import sync from '@/sync/service'
import { Movement, Till } from '@/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

export const useTillStore = defineStore(
  'till',
  () => {
    const router = useRouter()

    const startedShifts = ref<string[]>([])
    const startedShiftsWithCashAmount = ref<string[]>([])
    const shiftsEnabled = ref(false)
    const selectedCashTill = ref<Till | null>(null)

    const selectedCashTillIsStarted = computed(() => {
      return (
        selectedCashTill.value &&
        startedShifts.value.includes(selectedCashTill.value.id)
      )
    })

    const tillIsStarted = computed(() => {
      return (tillId: string) => startedShifts.value.includes(tillId)
    })

    const shiftIsStarted = computed(() => startedShifts.value.length > 0)

    const askForForTillStartAmount = computed(() => {
      return (
        shiftsEnabled.value &&
        !(
          selectedCashTill.value &&
          startedShiftsWithCashAmount.value.includes(selectedCashTill.value.id)
        )
      )
    })

    function refreshStartedShifts(shifts: string[]) {
      startedShifts.value = shifts
    }

    function refreshStartedShiftsWithCashAmount(shifts: string[]) {
      startedShiftsWithCashAmount.value = shifts
    }

    function refreshShiftsEnabled(enabled: boolean) {
      shiftsEnabled.value = enabled
      if (shiftsEnabled.value && !selectedCashTillIsStarted.value) {
        if (router.currentRoute.value.name !== 'employees') {
          router.push({ name: 'employees' })
        }
      }
    }

    function updateSelectedTillIfNull(till: Till) {
      if (!selectedCashTill.value) {
        selectedCashTill.value = till
      }
    }

    function setTill(till: Till) {
      selectedCashTill.value = till
    }

    function startShift({
      amount,
      tillId,
      method
    }: {
      amount: number
      tillId: string
      method: string
    }) {
      sync.record('shiftStarted', {
        amount,
        start: new Date(),
        tillId: tillId,
        method
      })
    }

    function endShift({
      amounts,
      tillId
    }: {
      amounts: { method: string; amount: number }[]
      tillId: string
    }) {
      sync.record('shiftEnded', {
        amounts,
        end: new Date(),
        tillId: tillId || selectedCashTill.value!.id
      })
    }

    function addMovement({
      amount,
      detail,
      movementType,
      tillId,
      method
    }: Movement) {
      sync.record('movementAdded', {
        amount,
        detail,
        movementType,
        method,
        tillId: tillId || selectedCashTill.value!.id,
        creationTime: new Date()
      })
    }

    return {
      startedShifts,
      startedShiftsWithCashAmount,
      shiftsEnabled,
      selectedCashTill,
      selectedCashTillIsStarted,
      tillIsStarted,
      shiftIsStarted,
      askForForTillStartAmount,
      refreshStartedShifts,
      refreshStartedShiftsWithCashAmount,
      refreshShiftsEnabled,
      updateSelectedTillIfNull,
      setTill,
      startShift,
      endShift,
      addMovement
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTillStore, import.meta.hot))
}
