<template>
  <div
    class="absolute top-0 left-0 z-20 flex flex-col justify-start w-screen h-screen pb-safe overflow-y-scroll scrolling-touch bg-n-900/80 backdrop-blur-sm"
  >
    <div class="rounded-2xl overflow-hidden flex h-full bg-n-800 m-5">
      <div class="px-5 py-6 min-w-0 flex-1 flex flex-col">
        <div class="mb-6 flex items-center justify-between h-9 mt-7 shrink-0">
          <div class="flex items-center gap-2 text-xl text-n-0">
            <div class="font-bold font-heading">
              {{ product.name }}
            </div>
            <div class="font-text font-normal">
              {{ $filters.currency(product.price || product.priceImpact) }}
            </div>
          </div>
          <quantity-selector
            v-if="showQuantity"
            class="text-n-0 font-body"
            :quantity="quantity"
            @update:quantity="updateQuantity"
          />
        </div>

        <div
          v-horizontal-scroll
          class="shrink-0 overflow-x-auto scrolling-touch"
        >
          <l-tabs v-model="selectedTabId" :tabs="tabs" type="primary" />
        </div>

        <template v-if="selectedModifiers.length > 0">
          <modifier-group-component
            v-if="modifierGroup.id === selectedTabId"
            :key="modifierGroup.name"
            v-model:modifiers="selectedModifiers[indexOfSelectedModifier]"
            :modifier-group="modifierGroup"
            @change="tryGotoNext"
          />
        </template>
        <template v-if="selectedTabId === TAB_OPEN_ATTRIBUTE">
          <open-modifiers-component
            :open-modifiers="openModifiers"
            @create="handleSaveOpenModifier"
            @delete="deleteOpenModifier"
          />
        </template>
        <template v-if="selectedTabId === TAB_COMMENT">
          <div class="h-full flex flex-col mt-4 md:mt-9 min-h-0">
            <l-field :label="$t('ordering.comments')">
              <l-input
                v-model="comments"
                type="textarea"
                class="w-full"
                maxlength="255"
              />
            </l-field>
          </div>
        </template>
      </div>

      <div class="w-80 shrink-0 flex flex-col border-l border-n-700">
        <div class="h-12 pr-1 flex justify-end items-center">
          <l-button
            size="small"
            icon="close"
            type="text"
            color="white"
            @click="$emit('close')"
          />
        </div>

        <div class="flex px-3 mb-2">
          <l-select v-model="course" size="small" :options="allCourses" />
        </div>

        <div class="h-full px-3 flex-1 overflow-y-auto">
          <div
            v-for="group in resumeModifiers"
            :key="group.id"
            class="flex flex-col first:border-none border-t border-n-700"
          >
            <div class="text-n-100 font-body px-4 py-3">
              {{ group.name }}
            </div>
            <div
              v-for="modifierItem in group.modifiers"
              :key="modifierItem.id"
              class="flex items-start gap-2 leading-5 justify-between text-sm text-n-0 font-body px-4 py-3"
            >
              <div class="w-5 text-center shrink-0">
                {{ modifierItem.quantity ?? 1 }}
              </div>
              <div>{{ modifierItem.name }}</div>
              <div
                class="text-v-300 font-text font-bold leading-5 text-xs ml-auto shrink-0 text-left"
              >
                {{ $filters.currency(modifierItem.priceImpact) }}
              </div>
            </div>
          </div>
          <div
            v-if="resumeModifiers.length === 0"
            class="flex h-full w-full items-center justify-center"
          >
            <empty-case
              :type="cartImage"
              class="max-w-48"
              enforced-translation-key="ordering.empty-modifiers"
            />
          </div>
        </div>

        <div class="px-6 py-5">
          <l-button
            class="w-full"
            :disabled="!isMinimumComplete"
            @click="saveProduct"
          >
            {{ $t('ordering.add') }}</l-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  LButton,
  LSelect,
  LTabs,
  LInput,
  LField,
  EmptyCase
} from '@last/core-ui/paprika'
import ModifierGroupComponent from '@/components/ordering/modifiers/ModifierGroup.vue'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import OpenModifiersComponent from '@/components/ordering/modifiers/OpenModifiers.vue'
import { useProductEditor } from './useProductEditor'
import { computed } from 'vue'
import { Product } from '@/types'
import { useI18n } from 'vue-i18n'
import { v4 as uuid } from 'uuid'
import { onMounted } from 'vue'
import cartImage from '@/assets/cart.svg?raw'

const TAB_COMMENT = 'TAB_COMMENT'
const TAB_OPEN_ATTRIBUTE = 'TAB_OPEN_ATTRIBUTE'

type ModifierChange = {
  id: string
  name: string
  modifiers: {
    id: string
    name: string
    priceImpact: number
    parentModifierId?: string
    quantity?: number
  }[]
}

const props = withDefaults(
  defineProps<{
    product: Product
    showQuantity?: boolean
    showCourse?: boolean
  }>(),
  {
    showQuantity: true,
    showCourse: true
  }
)
const emit = defineEmits(['close', 'saveProduct'])

const { t } = useI18n()
const {
  quantity,
  course,
  comments,
  selectedModifiers,
  openModifiers,
  selectedTabId,
  indexOfSelectedModifier,
  tabs,
  modifierGroup,
  allCourses,
  allSelectedModifiers,
  isMinimumComplete,
  deleteOpenModifier,
  updateQuantity,
  updateSelectedModifiers
} = useProductEditor(props.product)

onMounted(() => {
  updateSelectedModifiers()
})

const resumeModifiers = computed(() => {
  const selectedModifiersFlat = selectedModifiers.value
    .flatMap(groupModifiers =>
      Object.keys(groupModifiers).map(modifierId => ({
        parentModifierId: modifierId,
        quantity: groupModifiers[modifierId]
      }))
    )
    .reduce(
      (res, modifier) => {
        res[modifier.parentModifierId] = modifier
        return res
      },
      {} as Record<string, { parentModifierId: string; quantity: number }>
    )
  const sortedModifiers: ModifierChange[] = (props.product.modifierGroups || [])
    .map(group => ({
      id: group.id,
      name: group.name,
      modifiers: group.modifiers
        .filter(modifier => selectedModifiersFlat[modifier.id])
        .map(modifier => ({
          id: uuid(),
          name: modifier.name,
          priceImpact: modifier.priceImpact,
          parentModifierId: modifier.id,
          quantity: selectedModifiersFlat[modifier.id].quantity
        }))
    }))
    .filter(group => group.modifiers.length > 0)

  if (openModifiers.value.length > 0) {
    sortedModifiers.push({
      id: uuid(),
      name: t('ordering.open-modifiers'),
      modifiers: openModifiers.value
    })
  }
  return sortedModifiers
})

function saveProduct() {
  emit('saveProduct', {
    ...props.product,
    quantity: quantity.value,
    course: course.value,
    modifiers: allSelectedModifiers.value,
    comments: comments.value
  })
}

function tryGotoNext([selected, max]: [number, number]) {
  if (max && selected === max) {
    const selectedTabIdx = tabs.value.findIndex(
      tab => tab.id === selectedTabId.value
    )
    if (selectedTabIdx < tabs.value.length) {
      selectedTabId.value = tabs.value[selectedTabIdx + 1].id
    }
  }
}

function handleSaveOpenModifier(modifier: {
  name: string
  priceImpact: number
}) {
  openModifiers.value.push({
    id: uuid(),
    ...modifier
  })
}
</script>
