import appStorage from '@/drivers/appStorage'

const PREFIX = 'localDb:'

async function loadTables(tableKeys: string[]) {
  try {
    const keysData = await appStorage.keys()
    const values = await Promise.all(
      keysData
        .filter(key =>
          tableKeys.some(tableKey => key.startsWith(PREFIX + tableKey))
        )
        .map(key => key.replace(PREFIX, ''))
        .map(async key => {
          const lastIndex = key.lastIndexOf(':')
          const table = key.substring(0, lastIndex)
          const id = key.slice(lastIndex + 1)
          const value = await getItem(table, id)
          return { table, id, value }
        })
    )
    return values.reduce(
      (res, data) => {
        if (data.id !== 'singleValue') {
          res[data.table] = res[data.table] || {}
          res[data.table][data.id] = data.value
        } else {
          res[data.table] = data.value
        }
        return res
      },
      {} as Record<string, any>
    )
  } catch {
    return {}
  }
}

function mapRows(tables: Record<string, any>) {
  const rows = []
  for (const [table, data] of Object.entries(tables)) {
    if (typeof data === 'object') {
      for (const [id, value] of Object.entries(data)) {
        rows.push({ table, id, value })
      }
    } else {
      rows.push({ table, id: 'singleValue', value: data })
    }
  }
  return rows
}

function patchTables(tables: Record<string, any>) {
  return Promise.all(
    mapRows(tables).map(row => setItem(row.table, row.id, row.value))
  )
}

async function updateTables(tables: Record<string, any>) {
  const tableKeys = Object.keys(tables)
  const keysData = await appStorage.keys()
  const oldKeys =
    keysData.filter(key =>
      tableKeys.some(tableKey => key.startsWith(PREFIX + tableKey))
    ) || []
  const rows = mapRows(tables)
  const newKeys = rows.map(row => `${PREFIX}${row.table}:${row.id}`)
  const deletedKeys = oldKeys.filter(key => !newKeys.includes(key))
  await Promise.all([
    ...rows.map(row => setItem(row.table, row.id, row.value)),
    ...deletedKeys.map(key => appStorage.removeItem(key))
  ])
}

async function getItem(table: string, id: string) {
  return appStorage.getItem(`${PREFIX}${table}:${id}`)
}

function setItem(table: string, id: string, value: any) {
  return appStorage.setItem(`${PREFIX}${table}:${id}`, value)
}

function removeItem(table: string, id: string) {
  return appStorage.removeItem(`${PREFIX}${table}:${id}`)
}

async function clearAll() {
  const keysData = await appStorage.keys()
  return Promise.all(
    keysData
      .filter(key => key.startsWith(PREFIX))
      .map(key => appStorage.removeItem(key))
  )
}

async function clearTables(tableKeys: string[]) {
  const keysData = await appStorage.keys()
  return Promise.all(
    keysData
      .filter(key =>
        tableKeys.some(tableKey => key.startsWith(PREFIX + tableKey))
      )
      .map(key => appStorage.removeItem(key))
  )
}

export default {
  loadTables,
  updateTables,
  patchTables,
  setItem,
  removeItem,
  clearAll,
  clearTables
}
