import { datadogRum } from '@datadog/browser-rum'
import logger from './logger'
import type { Router } from 'vue-router'
import { Capacitor } from '@capacitor/core'

interface RUMLocation {
  id: string
  name: string
}

interface RUM {
  identify(location: RUMLocation): void
  setRouter(router: Router): void
  addError(error: unknown): void
}

class DatadogRUM implements RUM {
  private filteredErrors = [
    'Load failed', // Fetch error when no internet on iOS
    'Failed to fetch' // Fetch error when no internet on Android
  ]

  constructor() {
    if (
      import.meta.env.VITE_DATADOG_CLIENT_TOKEN &&
      Capacitor.getPlatform() !== 'web'
    ) {
      const appVersion = import.meta.env.VITE_APP_VERSION || 'unknown'
      try {
        datadogRum.init({
          applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
          clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
          site: 'datadoghq.com',
          service: 'pos',
          env: 'prod',
          version: appVersion,
          allowedTracingUrls: [import.meta.env.VITE_APP_BASE_URL],
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          trackViewsManually: true,
          defaultPrivacyLevel: 'mask-user-input',
          allowFallbackToLocalStorage: true,
          beforeSend: event => {
            if (
              event.type === 'error' &&
              this.filteredErrors.includes(event.error.message)
            ) {
              return false
            }
            return true
          }
        })
      } catch (error) {
        logger.error('Failed to initialize Datadog RUM')
      }
    }
  }

  identify(location: RUMLocation) {
    datadogRum.setUser({
      id: location.id,
      name: location.name
    })
  }

  setRouter(router: Router) {
    router.afterEach(to => {
      datadogRum.startView({ name: to.name?.toString() })
    })
  }

  addError(error: unknown) {
    datadogRum.addError(error)
  }
}

const rum: RUM = new DatadogRUM()

export default rum
