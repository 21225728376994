<template>
  <div
    v-click-outside="closeSelector"
    class="w-full relative"
    :class="disabledClasses"
  >
    <div
      id="selector"
      tabindex="0"
      class="w-full rounded-xl leading-tight outline-none border border-transparent flex justify-between items-center pr-3 cursor-pointer"
      :class="[...errorClasses, ...focusClasses, ...backgroundColors]"
      @click="openOrCloseSelector"
    >
      <div class="flex-1 min-w-0 flex items-center">
        <icon v-if="leftIcon" :name="leftIcon" class="ml-4 dark:text-n-0" />
        <div
          :class="[...sizeClasses, paddingClasses]"
          class="flex items-center w-full"
        >
          <div
            v-if="isColor"
            :style="{ background: text }"
            class="mx-6 my-2 w-full cursor-pointer rounded h-[1.7rem]"
          />
          <div v-else-if="text" class="truncate" :class="textColors">
            {{ text }}
          </div>
          <p v-else class="text-n-200 dark:text-n-300">
            {{ props.placeholder }}
          </p>
        </div>
      </div>
      <div class="flex">
        <icon v-if="rightIcon" :name="rightIcon" class="mr-4 dark:text-n-0" />
        <icon
          v-if="!canRemoveSelection"
          name="arrow-down"
          class="transition-all duration-500"
          :class="[...textColors, selectorOpen ? 'rotate-180' : '']"
        />
        <icon
          v-else
          name="close"
          class="transition-all duration-500"
          :class="[...textColors]"
          @click.stop="clearSelection"
        />
      </div>
    </div>
    <l-dropdown
      v-model:open="selectorOpen"
      :allow-remove="allowRemove"
      :append-to-parent="appendToParent"
      :multiselect="multiselect"
      :model-value="selectedArray"
      :options="props.options"
      @update:model-value="selectOption"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import { LDropdown } from '@last/core-ui/paprika'
import type { Option } from '@last/core-ui/paprika/components/dropdown/types'
import { useI18n } from 'vue-i18n'
import {
  useErrorClasses,
  useSizeClasses,
  usePaddingClasses,
  useFocusClasses,
  useDisabledClasses,
  useBackgroundColorsClasses,
  useTextColorsClasses
} from '@last/core-ui/paprika/components/classes'

const { t } = useI18n()

type Props = {
  modelValue: number | string | string[] | null
  defaultValue?: string | string[] | null
  options: Option[]
  placeholder?: string
  icon?: string
  iconPosition?: string
  size?: 'small' | 'medium' | 'large'
  allowRemove?: boolean
  error?: boolean
  multiselect?: boolean
  disabled?: boolean
  search?: boolean
  appendToParent?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  defaultValue: null,
  options: () => [],
  placeholder: '',
  iconPosition: 'right',
  size: 'small',
  icon: undefined,
  allowRemove: false,
  error: false,
  multiselect: false,
  disabled: false,
  search: false,
  appendToParent: false
})

const emit = defineEmits(['update:modelValue'])

const selectorOpen = ref(false)

function closeSelector() {
  document.getElementById('selector')?.blur()
  selectorOpen.value = false
}

function openSelector() {
  document.getElementById('selector')?.focus()
  selectorOpen.value = true
}

function openOrCloseSelector() {
  return selectorOpen.value ? closeSelector() : openSelector()
}

function clearSelection() {
  emit('update:modelValue', null)
}

function selectOption(newSelection: string[]) {
  let value = newSelection
    ? props.multiselect
      ? newSelection
      : newSelection[0]
    : null
  emit('update:modelValue', value)
  if (!props.multiselect) closeSelector()
}

const selectedArray = computed(() => {
  if (!props.modelValue) return []
  return Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
})

const canRemoveSelection = computed(() => {
  return props.allowRemove && selectedArray.value?.length > 0
})

const labels = computed<Record<string, string>>(() => {
  const result: Record<string, string> = {}
  const queue = [...props.options]
  while (queue.length > 0) {
    const option = queue.shift()
    if (!option) continue
    if (option.children) {
      queue.push(...option.children)
    }
    result[option.value] = option.label
  }
  return result
})

const text = computed(() => {
  if (!props.modelValue) return
  if (!Array.isArray(props.modelValue)) return labels.value[props.modelValue]

  let items: string[] = []
  for (let option of props.options) {
    if (props.modelValue.includes(option.value)) {
      items.push(labels.value[option.value])
    } else if (option.children) {
      const allChildrenSelected = option.children.every(child =>
        props.modelValue!.includes(child.value)
      )
      if (allChildrenSelected) {
        items.push(option.label + `(${t('core.all')})`)
      } else {
        let childrenItems: string[] = []
        for (let child of option.children) {
          if (props.modelValue.includes(child.value)) {
            childrenItems.push(child.label)
          }
        }
        if (childrenItems.length)
          items.push(option.label + `(${childrenItems.join(', ')})`)
      }
    }
  }
  return items.join(', ')
})

const isColor = computed(() => {
  if (!text.value) return false
  return text.value.match(`#([0-9]|[A-F]){6}`)
})

const leftIcon = computed(() => {
  return props.iconPosition === 'left' ? props.icon : undefined
})

const rightIcon = computed(() => {
  return props.iconPosition === 'right' ? props.icon : undefined
})

const disabledClasses = computed(() => useDisabledClasses(props.disabled))
const errorClasses = computed(() => useErrorClasses(props.error))
const sizeClasses = computed(() => useSizeClasses(props.size))
const paddingClasses = computed(() => usePaddingClasses(props.size))
const focusClasses = computed(() => useFocusClasses())
const backgroundColors = computed(() => useBackgroundColorsClasses())
const textColors = computed(() => useTextColorsClasses())
</script>
