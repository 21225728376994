<template>
  <div
    class="bg-n-800 rounded-full flex items-center justify-center text-white"
    :class="{
      'w-12 h-12 !text-[1.063rem]': size === 'extra-large', // 17px
      'w-10 h-10 !text-[0.938rem]': size === 'large', // 15px
      'w-8 h-8 !text-[0.813rem]': size === 'medium', // 13px
      'w-6 h-6 !text-[0.688rem]': size === 'small' // 11px
    }"
  >
    <div v-if="mode === 'text'" class="uppercasee">
      {{ textValue }}
    </div>
    <img v-else :src="imageValue" alt="Image" class="rounded-full" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
type Props = {
  size?: 'small' | 'medium' | 'large' | 'extra-large'
  mode?: 'text' | 'image'
  value?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
  mode: 'text',
  value: 'John Doe'
})

const textValue = computed<string>(() => {
  if (props.mode !== 'text') return ''

  if (props.value.length === 2) return props.value

  const words = props.value.trim().split(' ')
  if (words.length === 1) return words[0].substring(0, 2)
  else return words[0].substring(0, 1).concat(words[1].substring(0, 1))
})

const imageValue = computed<string>(() => {
  if (props.mode !== 'image') return ''

  return `https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/${props.value}`
})
</script>
