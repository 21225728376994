import api from '@/api'
import { Payment } from '@/types'
import appStorage from '@/drivers/appStorage'
import { logger } from '@/monitoring'
import { v4 as uuid } from 'uuid'

type AdyenConfig = {
  poiid: string
}

type AdyenPayment = Payment & {
  metadata: {
    tenderReference: string
    pspReference: string
  }
}

type AdyenCredentials = {
  username: string
  password: string
}

class AdyenDataphone {
  public initialized: boolean
  private config: AdyenConfig
  //private credentials: AdyenCredentials | undefined

  constructor(config: AdyenConfig) {
    this.initialized = false
    this.config = config
  }

  private async generateCredentials(): Promise<AdyenCredentials | null> {
    try {
      const username = uuid()
      const password = uuid()
      await api.post('pos/adyen/credentials', {
        username,
        password,
        poiid: this.config.poiid
      })
      return { username, password }
    } catch (e) {
      logger.error('Failed to generate Adyen credentials', e)
    }
    return null
  }

  private async getAuthCredentials(): Promise<AdyenCredentials | null> {
    const storedCredentials =
      await appStorage.getItem<AdyenCredentials>('adyenCredentials')
    if (storedCredentials) {
      return storedCredentials
    }
    const credentials = await this.generateCredentials()
    await appStorage.setItem('adyenCredentials', credentials)
    return credentials
  }

  async init() {
    /* const credentials = await this.getAuthCredentials()
    if (!credentials) {
      return false
    }
    this.credentials = credentials */
    this.initialized = true
    return true
  }

  async charge({
    amount,
    tabId,
    paymentId
  }: {
    amount: number
    tabId: string
    paymentId: string
  }) {
    try {
      const response = await api.post('pos/adyen/payment', {
        amount,
        tabId,
        paymentId,
        poiid: this.config.poiid
      })
      return response.data
    } catch (e: unknown) {
      throw new Error(e.response.data)
    }
  }

  async cancel({ paymentId, tabId }: { paymentId: string; tabId: string }) {
    try {
      const response = await api.put('pos/adyen/cancelPayment', {
        paymentId,
        tabId,
        poiid: this.config.poiid
      })
      return response.data
    } catch (e) {
      throw new Error(e.response.data)
    }
  }

  async refund(payment: AdyenPayment, tabId: string) {
    const response = await api.post('pos/adyen/refund', {
      tenderReference: payment.metadata.tenderReference,
      pspReference: payment.metadata.pspReference,
      amount: payment.amount,
      tabId,
      poiid: this.config.poiid
    })
    return response.data
  }
}

export default AdyenDataphone
