<template>
  <div class="relative">
    <button
      ref="statusButton"
      class="flex items-center justify-center relative gap-1 border border-n-0 text-n-0 text-xs font-semibold h-9 px-4 rounded-lg min-w-28"
      :class="{
        border: isFinalStatus
      }"
      @click="moveToNextStatus"
      @click.right.prevent="() => (dropdownOpen = true)"
    >
      <icon :name="statusIcon[deliveryOrder.status]" />
      {{ statusNames[deliveryOrder.status] }}
    </button>
    <l-dropdown
      v-model:open="dropdownOpen"
      v-model="selectedStatus"
      :options="options"
    />
  </div>
  <teleport to="#appRoot">
    <courier-selector
      v-if="showCourierSelector"
      @close="showCourierSelector = false"
      @assign-courier="setToOnDelivery"
    />
  </teleport>
</template>

<script setup lang="ts">
import { LDropdown, Icon } from '@last/core-ui/paprika'
import { toRef, defineProps, computed, ref } from 'vue'
import CourierSelector from '@/components/tabs/CourierSelector.vue'
import { useTabs } from '@/composables/useTabs'
import { useI18n } from 'vue-i18n'
import { onLongPress } from '@vueuse/core'
import type { Courier, DeliveryStatus } from '@/types'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'

interface Props {
  tabId: string
}

const props = defineProps<Props>()

const { t } = useI18n()
const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const statusButton = ref<HTMLElement>()
const tabId = toRef(props, 'tabId')
const showCourierSelector = ref(false)
const { tab, updateDeliveryOrderStatus } = useTabs(tabId)

const deliveryOrder = computed(() => tab.value.deliveryOrder!)

const statusOrder = computed(() => {
  let statuses = [
    'CREATED',
    'KITCHEN',
    'READY_TO_PICKUP',
    'ON_DELIVERY',
    'DELIVERED'
  ] as DeliveryStatus[]
  if (tab.value.pickupType !== 'ownDelivery') {
    statuses = statuses.filter(status => status !== 'ON_DELIVERY')
  }
  return statuses
})

const statusNames: Record<DeliveryStatus, string> = {
  CREATED: t('tabs.created'),
  KITCHEN: t('tabs.kitchen'),
  READY_TO_PICKUP: t('tabs.pickup'),
  ON_DELIVERY: t('tabs.on-delivery'),
  DELIVERED: t('tabs.delivered'),
  CANCELLED: t('tabs.cancelled'),
  CLOSED: t('tabs.closed')
}

const statusIcon = {
  CREATED: 'check',
  KITCHEN: 'kitchen',
  READY_TO_PICKUP: 'take-away',
  ON_DELIVERY: 'delivery',
  DELIVERED: 'check',
  CANCELLED: 'close',
  CLOSED: 'close'
}

const isFinalStatus = computed(() => {
  return ['DELIVERED', 'CANCELLED'].includes(deliveryOrder.value.status)
})

function moveToNextStatus() {
  if (dropdownOpen.value) return
  const currentIndex = statusOrder.value.indexOf(deliveryOrder.value.status)
  if (currentIndex === -1) return
  const nextStatus = statusOrder.value[currentIndex + 1]
  if (nextStatus) {
    moveToStatus(nextStatus)
  }
}

function moveToStatus(newStatus: DeliveryStatus) {
  if (
    newStatus === 'ON_DELIVERY' &&
    (!config.value.shipmentProviders?.selected || config.value.manualShipment)
  ) {
    showCourierSelector.value = true
    return
  } else {
    updateDeliveryOrderStatus({
      newStatus
    })
  }
  dropdownOpen.value = false
}

const selectedStatus = computed({
  get: () => [deliveryOrder.value.status],
  set: (selected: DeliveryStatus[]) => {
    moveToStatus(selected[0])
  }
})

function setToOnDelivery(courier: Courier) {
  updateDeliveryOrderStatus({
    newStatus: 'ON_DELIVERY',
    courier: courier
  })
  showCourierSelector.value = false
}

const dropdownOpen = ref(false)

function openDropdown() {
  dropdownOpen.value = true
}

onLongPress(statusButton, openDropdown, {
  modifiers: {
    prevent: true
  }
})

const options = Object.entries(statusNames).map(([value, label]) => {
  return {
    icon: statusIcon[value as DeliveryStatus],
    label,
    value
  }
})
</script>
