import api from '@/api'
import { useAuthStore } from '@/store/auth'
import { acceptHMRUpdate, defineStore } from 'pinia'
import {
  Catalogs,
  Catalog,
  CatalogProduct,
  CatalogCategory,
  CatalogProductCategory,
  CatalogModifierGroup,
  CatalogModifier,
  LocationBrandCatalogsBySource,
  CatalogProductNormalized,
  Source,
  SourceCatalog,
  PickupType
} from '@/types'
import { computed, ref } from 'vue'
import appStorage from '@/drivers/appStorage'

export const useCatalogStore = defineStore(
  'catalog',
  () => {
    const catalogs = ref<Record<string, Catalog>>({})
    const categories = ref<Record<string, CatalogCategory>>({})
    const defaultPosCatalog = ref<string | null>(null)
    const products = ref<Record<string, CatalogProduct>>({})
    const modifierGroups = ref<
      Record<string, Record<string, CatalogModifierGroup>>
    >({})
    const modifiers = ref<Record<string, Record<string, CatalogModifier>>>({})
    const productCategories = ref<Record<string, CatalogProductCategory>>({})
    const locationBrandCatalogsBySource = ref<LocationBrandCatalogsBySource>({})

    const productsById = computed(() => {
      return products.value
    })

    const getProductById = computed(() => {
      return (id: string, productCatalogId?: string) => {
        const catalogProduct = { ...products.value[id] }
        const catalogId = productCatalogId ?? catalogProduct?.catalogId
        const product: CatalogProductNormalized = {
          ...catalogProduct,
          categories: catalogProduct.categories ? [] : undefined,
          modifierGroups: catalogProduct.modifierGroups ? [] : undefined
        }
        if (!catalogId) return product
        if (catalogProduct.modifierGroups) {
          product.modifierGroups = catalogProduct.modifierGroups.map(
            (modifierGroupId, position) => {
              const catalogModifierGroup =
                modifierGroups.value[catalogId][modifierGroupId]
              const modifierGroup = {
                ...catalogModifierGroup,
                position,
                modifiers: [] as CatalogModifier[]
              }
              if (catalogModifierGroup) {
                modifierGroup.modifiers = catalogModifierGroup.modifiers.map(
                  modifierId => {
                    return { ...modifiers.value[catalogId][modifierId] }
                  }
                )
              }
              return modifierGroup
            }
          )
        }
        if (catalogProduct.categories) {
          product.categories = catalogProduct.categories.map(categoryId => {
            const catalogCategory = productCategories.value[categoryId]
            const category = {
              ...catalogCategory,
              products: [] as CatalogProductNormalized[]
            }
            if (catalogCategory) {
              category.products = catalogCategory.products.map(productId => {
                return getProductById.value(productId, catalogId)
              })
            }
            return category
          })
        }
        return product
      }
    })

    const productsByCatalog = computed(() => (catalogId: string | null) => {
      const catalogProducts = catalogId
        ? catalogs.value[catalogId].categories.flatMap(
            c => categories.value[c].products
          )
        : Object.keys(products.value)

      const uniqueProductIds = Array.from(new Set(catalogProducts))

      return uniqueProductIds.map(id => products.value[id])
    })

    const getCatalogIdByTabVirtualBrandId = computed(
      () =>
        (
          tabVirtualBrandId: string,
          pickupType?: PickupType,
          source?: Source
        ) => {
          if (!tabVirtualBrandId) return defaultPosCatalog.value

          let typeOfCatalog: keyof SourceCatalog | null = null
          if (pickupType === 'delivery' || pickupType === 'ownDelivery')
            typeOfCatalog = 'deliveryCatalogId'
          else if (pickupType === 'takeAway')
            typeOfCatalog = 'takeawayCatalogId'

          let catalogId: string | null = null
          if (typeOfCatalog) {
            const locationVirtualBrandAssignments =
              locationBrandCatalogsBySource.value[tabVirtualBrandId]
            source = source!.toLowerCase() as Source

            catalogId =
              locationVirtualBrandAssignments[source]?.[typeOfCatalog] ??
              locationVirtualBrandAssignments['default']?.[typeOfCatalog]
          }

          return catalogId || defaultPosCatalog.value
        }
    )

    const allCourses = computed(() => {
      return Object.values(catalogs.value)[0].courses.map(course => ({
        label: course,
        value: course
      }))
    })

    async function refreshCatalogs(inputCatalogs?: Catalogs) {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      if (!inputCatalogs?.catalogs) {
        inputCatalogs = await api
          .get<Catalogs>('/pos/beta/catalogs')
          .then(res => res.data)
        if (!inputCatalogs) return
      }
      appStorage.setItem('catalogs', inputCatalogs)

      catalogs.value = inputCatalogs.catalogs.reduce(
        (acc, catalog) => {
          acc[catalog.catalog.id] = catalog.catalog
          return acc
        },
        {} as Record<string, Catalog>
      )

      const productsTmp: { [key: string]: CatalogProduct } = {}
      const categoriesTmp: { [key: string]: CatalogCategory } = {}
      const productCategoriesTmp: { [key: string]: CatalogProductCategory } = {}
      const modifierGroupsTmp: {
        [key: string]: { [key: string]: CatalogModifierGroup }
      } = {}
      const modifiersTmp: {
        [key: string]: { [key: string]: CatalogModifier }
      } = {}
      for (const catalog of inputCatalogs.catalogs) {
        const catalogId = catalog.catalog.id
        Object.assign(modifierGroupsTmp, { [catalogId]: {} })
        Object.assign(modifiersTmp, { [catalogId]: {} })
        Object.assign(productsTmp, catalog.products)
        Object.assign(categoriesTmp, catalog.categories)
        Object.assign(productCategoriesTmp, catalog.productCategories)
        Object.assign(modifierGroupsTmp[catalogId], catalog.modifierGroups)
        Object.assign(modifiersTmp[catalogId], catalog.modifiers)
      }

      modifiers.value = modifiersTmp
      modifierGroups.value = modifierGroupsTmp
      productCategories.value = productCategoriesTmp
      products.value = productsTmp
      categories.value = categoriesTmp

      defaultPosCatalog.value = inputCatalogs.defaultPosCatalog
      locationBrandCatalogsBySource.value =
        inputCatalogs.locationBrandCatalogsBySource
    }

    async function refreshCatalog(catalog: any) {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      if (!catalog) return

      const catalogId = catalog.catalog.id

      catalogs.value[catalogId] = { ...catalog.catalog }

      products.value = { ...products.value, ...(catalog.products || {}) }
      categories.value = { ...categories.value, ...(catalog.categories || {}) }
      productCategories.value = {
        ...productCategories.value,
        ...(catalog.productCategories || {})
      }
      modifierGroups.value = {
        ...modifierGroups.value,
        ...(catalog.modifierGroups || {})
      }
      modifiers.value = { ...modifiers.value, ...(catalog.modifiers || {}) }
    }

    return {
      catalogs,
      categories,
      defaultPosCatalog,
      products,
      modifierGroups,
      modifiers,
      productCategories,
      locationBrandCatalogsBySource,
      productsById,
      getProductById,
      productsByCatalog,
      getCatalogIdByTabVirtualBrandId,
      allCourses,
      refreshCatalogs,
      refreshCatalog
    }
  },
  {
    persist: false
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCatalogStore, import.meta.hot))
}
