/* eslint-disable no-console */
import { Capacitor } from '@capacitor/core'
import { datadogLogs } from '@datadog/browser-logs'

interface Location {
  id: string
  name: string
}

interface Logger {
  info(message: string): void
  info(message: string, data: Record<string, any>): void
  debug(message: string, data?: Record<string, any>): void
  error(message: string): void
  error(message: string, error: Error): void
  error(message: string, data: Record<string, any>): void
  error(message: string, data: Record<string, any>, error: Error): void
  identify(location: Location): void
}

class RemoteLogger implements Logger {
  constructor() {
    if (
      import.meta.env.VITE_DATADOG_CLIENT_TOKEN &&
      Capacitor.getPlatform() !== 'web'
    ) {
      const appVersion = import.meta.env.VITE_APP_VERSION || 'unknown'
      datadogLogs.init({
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'pos',
        env: 'prod',
        version: appVersion
      })
    }
  }

  info(message: string, data?: Record<string, any>) {
    datadogLogs.logger.info(message, data)
  }

  debug(_message: string, _data: Record<string, any>): void {}

  error(
    message: string,
    errorOrData?: Error | Record<string, any>,
    error?: Error
  ) {
    if (!errorOrData && !error) {
      datadogLogs.logger.error(message)
    } else if (errorOrData instanceof Error) {
      datadogLogs.logger.error(message, {}, errorOrData)
    } else {
      datadogLogs.logger.error(message, errorOrData, error)
    }
  }

  identify(location: Location): void {
    datadogLogs.setGlobalContext({
      locationId: location.id,
      locationName: location.name
    })
  }
}

class ConsoleLogger implements Logger {
  info(message: string, data?: Record<string, any>) {
    console.info(message, data)
  }

  debug(message: string, data: Record<string, any>): void {
    console.debug(message, data)
  }

  error(
    message: string,
    errorOrData?: Error | Record<string, any>,
    error?: Error
  ) {
    if (!errorOrData && !error) {
      console.error(message)
    } else if (errorOrData instanceof Error) {
      console.error(message, errorOrData)
    } else {
      console.error(message, errorOrData, error)
    }
  }

  identify(location: Location): void {
    console.debug('Identify:', location)
  }
}

let logger: Logger

if (import.meta.env.VITE_DATADOG_CLIENT_TOKEN) {
  logger = new RemoteLogger()
} else {
  logger = new ConsoleLogger()
}

export default logger
