<template>
  <div class="flex flex-col items-center">
    <div class="w-22 h-22 mb-4 dark:hidden" v-html="svg.light" />
    <div class="w-22 h-22 mb-4 hidden dark:block" v-html="svg.dark" />
    <div
      class="lowercase font-body text-sm text-n-400 dark:text-n-0 first-letter:uppercase text-center"
    >
      {{ text }}
    </div>
    <l-button
      v-if="props.buttonText"
      class="mt-4"
      size="small"
      @click="$emit('action')"
    >
      {{ props.buttonText }}
    </l-button>
  </div>
</template>

<script lang="ts">
const modules = import.meta.glob('../../components/assets/svg/**/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true
})
</script>

<script setup lang="ts">
import { LButton } from '@last/core-ui/paprika'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
  type: 'error' | 'empty' | 'warning' | string | { light: string; dark: string }
  itemTranslationKey?: string
  enforcedTranslationKey?: string
  buttonText?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: 'error',
  itemTranslationKey: 'empty-case.anything',
  enforcedTranslationKey: '',
  buttonText: ''
})

defineEmits<{
  (e: 'action'): void
}>()

const { t } = useI18n()

const svg = computed(() => {
  let iconName = ''
  switch (props.type) {
    case 'empty':
      iconName = 'empty-case'
      break
    case 'warning':
      iconName = 'warning'
      break
    case 'error':
      iconName = 'dizzy-robot'
      break
    default:
      return typeof props.type === 'string'
        ? { light: props.type, dark: props.type }
        : props.type
  }
  return {
    light: modules['../../components/assets/svg/' + iconName + '.svg'],
    dark: modules['../../components/assets/svg/' + iconName + '-dark.svg']
  }
})

const text = computed(() => {
  if (props.enforcedTranslationKey) {
    return t(props.enforcedTranslationKey)
  }
  switch (props.type) {
    case 'empty':
      return t('empty-case.empty-title', { item: t(props.itemTranslationKey) })
    default:
      return t('empty-case.error-title')
  }
})
</script>
