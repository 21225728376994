import api from '@/api'
import app from '@/app'
import images from '@/images'
import i18n from '@/i18n'
import Printer from '@/integrations/printer/printer.js'
import sync from '@/sync/service'
import { Capacitor } from '@capacitor/core'
import moment, { Moment } from 'moment-timezone'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import Dataphone from '@/integrations/dataphone/dataphone.js'
import lastUtils from '@last/core/src/lastUtils'
import { useAuthStore } from '@/store/auth'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useScrapperStore } from '@/store/scrapper.js'
import { useTillStore } from '@/store/till'
import { OrderingMode, PaymentMethod, Till } from '@/types'
import { CountryCode } from 'libphonenumber-js'
import { computed, ref } from 'vue'
import { addDevice } from '@/api/device'
import { DeviceConfig } from '@/types/device'

const { t } = i18n.global

type Config = {
  employees: {
    selectionType: string
  }
  virtualBrands: {
    id: string
    name: string
    enabled: boolean
    imageId: string
    coverImageId?: string
    deliveryConfig?: any
  }[]
  reservations: Record<string, any>
  tills: {
    cash: Till[]
  }
  featureToggles: Record<string, any>
  organizationConfig: Record<string, any>
  locationCountryCode: CountryCode
  locationName: string
  shipmentProviders?: {
    selected: string | null
    providers: {
      name: string
      config?: any
    }[]
  }
  manualShipment?: boolean
  lastProductPosEnabled?: boolean
  lastProductBookingsEnabled?: boolean
  showProductImages?: boolean
  currencyCode: string
  paymentMethods: string[]
  paymentMethodsConfig: PaymentMethod[]
  blindTill: boolean
  enableKitchenOrders: boolean
  printers: Record<string, any>
  autoprintReceipts: boolean
  autoprintKitchenOrders: boolean
  autoprintBills: boolean
  dataphoneConfigs: any[]
  adyenDataphones: any[]
  timeoutSeconds?: number
  glovoScrapperStores: any[]
  logoUrl?: string
  language?: string
  billCopies: number
  workingTime?: {
    end: string
    timezone?: string
  }
  isHotel?: boolean
  company?: any
  taxRate?: any
  ticketInfo?: any
  deliveryTillId?: string
  defaultKitchenPrinter?: string
  controlPrinter?: string
  locationBrandId?: string
  preparationMinutes: number
  schedulingMarginMinutes?: string
  tabOrdering?: OrderingMode
  showTakeAwayTabPopup: boolean
}

export const useConfigStore = defineStore(
  'config',
  () => {
    const printers = ref<Record<string, any>>({})
    const config = ref<Config>({
      employees: {
        selectionType: 'names'
      },
      virtualBrands: [],
      reservations: {},
      tills: {
        cash: []
      },
      featureToggles: {},
      organizationConfig: {},
      locationCountryCode: 'ES',
      currencyCode: 'EUR',
      paymentMethods: [],
      paymentMethodsConfig: [],
      blindTill: false,
      billCopies: 1,
      locationName: '',
      enableKitchenOrders: true,
      glovoScrapperStores: [],
      printers: [],
      autoprintReceipts: false,
      autoprintKitchenOrders: false,
      autoprintBills: false,
      dataphoneConfigs: [],
      adyenDataphones: [],
      preparationMinutes: 0,
      showTakeAwayTabPopup: false
    })
    const mute = ref(localStorage.getItem('mute') || false)
    const device = ref<DeviceConfig>({
      mode: 'master',
      platform: 'web'
    })
    const demoMode = ref(false)
    const updateTime = ref<Moment | null>(null)

    const virtualBrands = computed(() => config.value.virtualBrands)

    const reservations = computed(() => config.value.reservations)

    const tillNameByTillId = computed(() => {
      const values = Object.values(config.value.tills).flat()
      return (tillId: string) => values.find(till => till.id === tillId)!.name
    })

    const tills = computed(() => {
      return config.value.tills
    })

    const currencyIcon = computed(() => {
      return lastUtils.getCurrencyIcon(config.value?.currencyCode)
    })

    const appVersion = computed(() => {
      return device.value.appVersion || 'web'
    })

    const getVirtualBrand = computed(() => (virtualBrandId: string) => {
      return config.value.virtualBrands?.find(b => b.id === virtualBrandId)
    })

    const shipmentProviders = computed(() => config.value.shipmentProviders)

    const currentAdyenDataphone = computed(() => {
      const poiid = app.serial
      return config.value.adyenDataphones?.find(
        dataphone => dataphone.poiid === poiid
      )
    })

    async function refreshConfig(inputConfig?: Config) {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      let newConfig = inputConfig
      if (!newConfig) {
        try {
          newConfig = (await api.get('/config')).data as Config
        } catch (e) {
          newConfig = {
            ...config.value,
            printers: Object.values(config.value.printers)
          }
        }
      }
      const stores = newConfig.glovoScrapperStores
      const scrapper = useScrapperStore()
      if (stores?.length > 0) {
        scrapper.updateStores(stores)
      }

      let channel
      if (
        newConfig?.featureToggles &&
        Object.keys(newConfig.featureToggles).includes('release_channel_beta')
      ) {
        channel = 'beta'
      }
      app.checkForUpdates(channel)

      newConfig.virtualBrands.forEach(brand => {
        images.preloadLogo(brand.imageId)
      })
      let language = newConfig.language || 'es'
      if (demoMode.value) {
        language = navigator.language.split('-')[0]
      }
      i18n.global.locale.value = language
      if (newConfig.workingTime?.timezone) {
        moment.tz.setDefault(newConfig.workingTime?.timezone || 'Europe/Madrid')
      }
      moment.locale(language)
      moment.updateLocale(language, {
        relativeTime: {
          future: t('relative-time.future', { time: '%s' }),
          past: '%s',
          s: t('relative-time.one-minute'),
          ss: t('relative-time.one-minute'),
          m: t('relative-time.one-minute'),
          mm: t('relative-time.minutes', { time: '%d' }),
          h: t('relative-time.one-hour'),
          hh: t('relative-time.hours'),
          d: t('relative-time.hours'),
          dd: t('relative-time.hours'),
          w: t('relative-time.hours'),
          ww: t('relative-time.hours'),
          M: t('relative-time.hours'),
          MM: t('relative-time.hours'),
          y: t('relative-time.hours'),
          yy: t('relative-time.hours')
        }
      })
      Printer.loadPrinters(newConfig.printers)
      const printers = newConfig.printers.reduce((res, printer) => {
        res[printer.id] = printer
        return res
      }, {})
      config.value = {
        ...newConfig,
        printers
      }
      refreshDeviceInfo()
      refreshUpdateTime()
    }

    async function refreshDeviceInfo() {
      let addedDevice: DeviceConfig | {} = {}
      const platform = Capacitor.getPlatform()
      if (platform !== 'web') {
        const deviceInfo = await app.getDeviceInfo()
        // HACK: This is to keep the same behavior as the old code. If we have a
        // device stored we reuse the same id.
        // This should not be necessary because that seems to be already the behavior
        // of Capacitor. In Electron it keeps the generated id in the localStorage.
        if (device.value.id) {
          deviceInfo.id = device.value.id
        }
        addedDevice = await addDevice(deviceInfo)
        const auth = useAuthStore()
        auth.setAccessToken(addedDevice.accessToken)
        device.value = addedDevice as DeviceConfig
      }
      const enabledTills = tills.value.cash
      const preferredTill = enabledTills?.find(
        till => till.id === addedDevice.preferredTill
      )
      const till = useTillStore()
      if (preferredTill) {
        till.setTill(preferredTill)
      } else if (enabledTills.length === 1) {
        till.setTill(enabledTills[0])
      } else if (enabledTills.length > 1) {
        till.updateSelectedTillIfNull(enabledTills[0])
      }
      CashMachine.init(config.value, addedDevice.preferredCashMachine)
      Dataphone.init(config.value, addedDevice.preferredDataphone)
    }

    function toggleMute() {
      mute.value = !mute.value
      localStorage.setItem('mute', mute.value)
    }

    function updateShipmentProvider(shipmentProvider: {
      provider: string | null
      ownFleetEnabled: boolean
    }) {
      sync.record('updateShipmentProvider', shipmentProvider)
    }

    function updateManualShipment(manualShipment: boolean) {
      sync.record('updateManualShipment', manualShipment)
    }

    function activateDemoMode() {
      demoMode.value = true
    }

    async function updateHardwarePreferences(hardwarePreferences: any) {
      if ('preferredCashMachine' in hardwarePreferences)
        CashMachine.init(config.value, hardwarePreferences.preferredCashMachine)

      if ('preferredDataphone' in hardwarePreferences)
        Dataphone.init(config.value, hardwarePreferences.preferredDataphone)

      device.value = {
        ...device.value,
        ...hardwarePreferences
      }

      if (device.value.id) {
        await api.put(
          `/devices/hardware-preferences/${device.value.id}`,
          hardwarePreferences
        )
      }
    }

    function refreshUpdateTime() {
      updateTime.value = moment()
    }

    const isShipmentProvidersEnabled = computed<boolean>(() => {
      if (!shipmentProviders.value || !shipmentProviders.value.providers)
        return false

      return shipmentProviders.value.providers.some(provider => {
        return provider.config?.enabled
      })
    })

    return {
      printers,
      config,
      mute,
      device,
      demoMode,
      updateTime,
      virtualBrands,
      reservations,
      tillNameByTillId,
      tills,
      currencyIcon,
      appVersion,
      getVirtualBrand,
      shipmentProviders,
      currentAdyenDataphone,
      refreshConfig,
      refreshDeviceInfo,
      toggleMute,
      updateShipmentProvider,
      updateManualShipment,
      activateDemoMode,
      updateHardwarePreferences,
      refreshUpdateTime,
      isShipmentProvidersEnabled
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
