<template>
  <product-component
    :product="product"
    :disabled="productIsBilled"
    :disable-swipe="disableSwipe"
    :show-quantity-selector="!disabledQuantitySelector"
    show-comments
    allow-remove
    show-sent-to-kitchen
    show-billing-status
    @selected="emit('selected')"
  ></product-component>
</template>

<script setup lang="ts">
import ProductComponent from '@/components/core/Product.vue'
import useProduct from '@/composables/useProduct'
import type { TabProduct } from '@/types'

const props = defineProps<{
  product: TabProduct
  disableSwipe?: boolean
  disabledQuantitySelector?: boolean
}>()

const emit = defineEmits(['selected'])

const { productIsBilled } = useProduct(props.product)
</script>
