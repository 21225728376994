<template>
  <div
    class="fixed top-0 left-0 h-full pb-safe w-screen z-30 overflow-hidden flex flex-col justify-center items-center backdrop-blur-sm"
    @click="$emit('close')"
  >
    <cancel-shipment-popup
      v-if="showCancelShipmentPopup"
      :courier="jobCourier"
      :tab-id="tab.id"
      @close="$emit('close')"
    />
    <completed-shipment-popup
      v-else-if="!loading && showCompletedShipmentPopup"
      @close="$emit('close')"
    />
    <cancelled-shipment-popup
      v-else-if="!loading && showCancelledShipmentPopup"
      :cancel-reason="job.cancelReason"
      @close="$emit('close')"
    />
    <map-modal v-else ref="mapModal" :job="job" :tab="tab" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import MapModal from './MapModal.vue'
import CancelledShipmentPopup from './CancelledShipmentPopup.vue'
import CompletedShipmentPopup from './CompletedShipmentPopup.vue'
import CancelShipmentPopup from './CancelShipmentPopup.vue'
import { Tab } from '@/types'
import { getShipment } from '@/api/shipment'
import { ShipmentJob } from '@/types/shipment'

type Props = {
  tab: Tab
  cancel?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  cancel: false
})

defineEmits(['close'])

const pollingInterval = ref<number | null>(null)
const job = ref<ShipmentJob | null>(null)
const loading = ref(false)

const jobCourier = computed(() => job.value?.courier)

const showCancelShipmentPopup = computed(() => {
  return (
    props.cancel &&
    (!job.value || (!job.value.isDelivered && !job.value.isCancelled))
  )
})

const showCompletedShipmentPopup = computed(() => {
  return job.value && job.value.isDelivered
})

const showCancelledShipmentPopup = computed(() => {
  return job.value && job.value.isCancelled
})

async function getDeliveryInfo() {
  if (props.tab.deliveryOrder.shipment) {
    job.value = await getShipment(props.tab.id)
  } else {
    job.value = {
      courier: {},
      deliveries: [
        {
          address: props.tab.deliveryOrder.address,
          latitude: props.tab.deliveryOrder.latitude,
          longitude: props.tab.deliveryOrder.longitude
        }
      ],
      shipmentPickedUp: true
    }
  }
}

onMounted(() => {
  pollingInterval.value = window.setInterval(getDeliveryInfo, 3_000)
  getDeliveryInfo()
})

onBeforeUnmount(() => {
  if (pollingInterval.value) {
    window.clearInterval(pollingInterval.value)
  }
})
</script>
