<template>
  <tippy
    ref="tippy"
    :interactive="false"
    :arrow="true"
    :trigger="trigger"
    :placement="placement"
    theme="tooltip"
    :append-to="() => appendTarget"
  >
    <slot />
    <template #content>
      <slot name="content" />
    </template>
  </tippy>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'
import { Tippy, type TippyOptions } from 'vue-tippy'

type Props = {
  trigger: TippyOptions['trigger']
  placement: TippyOptions['placement']
}
defineProps<Props>()

const appendTarget = document.body
const tippyRef = useTemplateRef<typeof Tippy>('tippy')

defineExpose({
  show: () => {
    tippyRef.value?.show()
  },
  hide: () => {
    tippyRef.value?.hide()
  }
})
</script>

<style>
@import 'tippy.js/dist/tippy.css';

.tippy-box[data-theme~='tooltip'] {
  @apply font-body text-sm rounded-md text-n-400 bg-n-50;
}

.tippy-box[data-theme~='tooltip'][data-placement^='top'] > .tippy-arrow:before {
  @apply border-t-n-50;
}

.tippy-box[data-theme~='tooltip'][data-placement^='bottom']
  > .tippy-arrow:before {
  @apply border-b-n-50;
}

.tippy-box[data-theme~='tooltip'][data-placement^='left']
  > .tippy-arrow:before {
  @apply border-l-n-50;
}

.tippy-box[data-theme~='tooltip'][data-placement^='right']
  > .tippy-arrow:before {
  @apply border-r-n-50;
}

.tippy-box[data-theme~='tooltip'] > .tippy-backdrop {
  @apply bg-none;
}

.tippy-content[data-theme~='tooltip'] {
  @apply p-4;
}

/** Dark mode */
.tippy-box[data-theme~='tooltip']:where(.dark, .dark *) {
  @apply bg-n-600;
}

.tippy-box[data-theme~='tooltip'][data-placement^='top']:where(.dark, .dark *)
  > .tippy-arrow:before {
  @apply border-t-n-600;
}

.tippy-box[data-theme~='tooltip'][data-placement^='bottom']:where(
    .dark,
    .dark *
  )
  > .tippy-arrow:before {
  @apply border-b-n-600;
}

.tippy-box[data-theme~='tooltip'][data-placement^='left']:where(.dark, .dark *)
  > .tippy-arrow:before {
  @apply border-l-n-600;
}

.tippy-box[data-theme~='tooltip'][data-placement^='right']:where(.dark, .dark *)
  > .tippy-arrow:before {
  @apply border-r-n-600;
}
</style>
