<template>
  <div class="flex flex-col h-screen pb-safe overflow-hidden">
    <top-bar class="flex-none" @back="router.go(-1)" />
    <div class="flex flex-1 flex-col bg-n-800 w-full overflow-hidden">
      <div class="flex-none w-full px-24">
        <l-search-bar
          v-model="searchQuery"
          class="py-4"
          icon="search"
          size="medium"
          :placeholder="$t('tabs.search-placeholder')"
        />
      </div>
      <transition name="switch" mode="out-in">
        <div v-if="!emptyResults" class="flex-1 overflow-hidden">
          <div class="h-full overflow-y-scroll px-24 scrolling-touch">
            <div v-if="showRecent" class="text-n-0 font-bold text-xl pb-1">
              {{ $t('search.recent') }}
            </div>
            <template v-if="filteredTabs && filteredTabs.length > 0">
              <div class="text-n-0 font-normal text-base pb-4">
                {{ $t('search.tabs') }}
              </div>
              <transition-group
                name="results-list"
                class="grid grid-cols-[repeat(auto-fill,minmax(18rem,1fr))] gap-4 pb-4"
                tag="div"
                @before-leave="beforeLeave"
              >
                <template v-for="tab in filteredTabs" :key="tab.id">
                  <search-result-item :item="tab" type="tab" />
                </template>
              </transition-group>
            </template>
            <template
              v-if="filteredReservations && filteredReservations.length > 0"
            >
              <div class="text-n-0 font-normal text-base pb-4">
                {{ $t('search.reservations') }}
              </div>

              <transition-group
                name="results-list"
                class="grid grid-cols-[repeat(auto-fill,minmax(18rem,1fr))] gap-4 pb-4"
                tag="div"
                @before-leave="beforeLeave"
              >
                <template
                  v-for="reservation in filteredReservations"
                  :key="reservation.id"
                >
                  <search-result-item :item="reservation" type="reservation" />
                </template>
              </transition-group>
            </template>
          </div>
        </div>
        <empty-case
          v-else
          class="flex-1 align-center justify-center"
          type="empty"
          enforced-translation-key="search.no-results"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import TopBar from '@/components/core/TopBar.vue'
import { EmptyCase, LSearchBar } from '@last/core-ui/paprika'
import { useTabsStore } from '@/store/tabs'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { Tab } from '@/types'
import SearchResultItem from '@/components/Search/SearchResultItem.vue'
import { useReservationsStore } from '@/store/reservations'
import { useFuse } from '@vueuse/integrations/useFuse'

const tabsStore = useTabsStore()
const { tabs } = storeToRefs(tabsStore)
const reservationsStore = useReservationsStore()
const { sortedReservations } = storeToRefs(reservationsStore)
const router = useRouter()

const searchQuery = ref('')

const showRecent = computed(() => !searchQuery.value)

const emptyResults = computed(() => {
  return (
    filteredTabs.value?.length === 0 && filteredReservations.value?.length === 0
  )
})

const sortedTabs = computed(() => {
  return Object.values(tabs.value).sort(
    (a: Tab, b: Tab) =>
      new Date(b.activationTime).getTime() -
      new Date(a.activationTime).getTime()
  )
})

const searchTabs = useFuse(searchQuery, sortedTabs, {
  fuseOptions: {
    keys: ['name', 'code', 'customerInfo.name', 'customerInfo.phoneNumber'],
    threshold: 0.4,
    minMatchCharLength: 2
  }
})

const searchReservations = useFuse(searchQuery, sortedReservations, {
  fuseOptions: {
    keys: ['name', 'phoneNumber']
  }
})

const filteredReservations = computed(() => {
  return searchQuery.value
    ? searchReservations.results.value.map(r => r.item)
    : sortedReservations.value.slice(0, 8)
})

const filteredTabs = computed(() => {
  return searchQuery.value
    ? searchTabs.results.value.map(r => r.item)
    : sortedTabs.value.slice(0, 8)
})

function beforeLeave(element: Element) {
  const el = element as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.results-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}
.results-list-enter-active {
  transition: all 0.4s ease;
}
.results-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}
.results-list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}
.results-list-move {
  transition: all 0.3s ease;
}

.switch-enter-from,
.switch-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.switch-enter-active {
  transition: all 0.3s ease;
}
.switch-leave-active {
  transition: all 0.3s ease;
}
</style>
