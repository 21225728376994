<template>
  <div class="aspect-square max-w-80 m-8 relative">
    <div class="absolute grid grid-cols-5 grid-rows-5 -inset-6">
      <div
        :class="[getGridClasses('t', 'l'), { 'border-r-300': qrDetected }]"
      />
      <div
        class="rounded-tl-[2.4rem] rounded-tr-[2.4rem] rounded-bl-[2.4rem] rounded-br-[2.4rem]"
      />
      <div :class="[getGridClasses('t'), { 'border-r-300': qrDetected }]" />
      <div />
      <div
        :class="[getGridClasses('t', 'r'), { 'border-r-300': qrDetected }]"
      />
      <div class="col-span-5" />
      <div :class="[getGridClasses('l'), { 'border-r-300': qrDetected }]" />
      <div class="col-span-3" />
      <div :class="[getGridClasses('r'), { 'border-r-300': qrDetected }]" />
      <div class="col-span-5" />
      <div
        :class="[getGridClasses('b', 'l'), { 'border-r-300': qrDetected }]"
      />
      <div />
      <div :class="[getGridClasses('b'), { 'border-r-300': qrDetected }]" />
      <div />
      <div
        :class="[getGridClasses('b', 'r'), { 'border-r-300': qrDetected }]"
      />
    </div>
    <div class="overflow-hidden w-full h-full rounded-3xl">
      <qrcode-stream class="object-contain" @detect="onDetect" @init="onInit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import lastUtils from '@last/core/src/lastUtils'
import { QrcodeStream } from 'vue-qrcode-reader'

const emit = defineEmits(['close', 'cancel', 'update:value', 'error'])

const qrDetected = ref(false)

async function onDetect(result: any) {
  qrDetected.value = true
  await lastUtils.sleep(1000)
  qrDetected.value = false
  emit('update:value', result[0].rawValue)
}

async function onInit(promise: Promise<void>) {
  try {
    await promise
  } catch (e) {
    emit('error')
  }
}

type GridClassPositions = 't' | 'b' | 'l' | 'r'

function getGridClasses(
  pos1: GridClassPositions,
  pos2?: GridClassPositions
): string {
  if (!pos2) {
    return `border-${pos1}-4 border-n-0`
  }

  return `rounded-${pos1}${pos2}-[2.4rem] border-${pos1}-4 border-${pos2}-4 border-n-0`
}
</script>
