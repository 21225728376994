import { datadogRum } from '@datadog/browser-rum'
import EscPosEncoder from './esc-pos-encoder.js'

class UsbPrinter {
  constructor(config) {
    try {
      const { ipcRenderer } = window.require('electron')
      this.ipcRenderer = ipcRenderer
      this.config = config
      ipcRenderer.invoke('usb-printer-init', config).catch(error => {
        datadogRum.addError(error)
      })
    } catch {
      throw new Error('No USB capability')
    }
  }

  async printImage(imageData) {
    let encoder = new EscPosEncoder()
    var img = new Image()
    img.src = imageData
    let ipcRenderer = this.ipcRenderer
    let config = this.config
    img.onload = function () {
      let width = 504
      let ratio = width / img.width
      let height = Math.round((img.height * ratio) / 8) * 8
      let result = encoder
        .align('center')
        .image(img, width, height)
        .newline()
        .newline()
        .newline()
        .newline()
        .newline()
        .raw([0x1d, 0x56, 0x01])
        .encode()
      ipcRenderer.invoke('usb-printer-send-data', config.name, result)
    }
  }

  openCashDrawer() {
    let encoder = new EscPosEncoder()
    let result = encoder
      .raw([0x1b, 0x70, 0x00])
      .raw([0x1b, 0x70, 0x01])
      .encode()
    this.ipcRenderer.invoke('usb-printer-send-data', this.config.name, result)
  }
}

export default UsbPrinter
