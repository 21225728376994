<template>
  <l-modal
    v-if="showCustomerEditor"
    :button-text="$t('ctas.continue')"
    class="!pb-[var(--keyboard-height,_16px)]"
    @action="checkUserForm()"
    @close="$emit('close')"
  >
    <div v-if="edit">
      <div class="flex gap-4">
        <l-field class="flex-1" :label="$t('new-delivery.client-name-label')">
          <l-input
            v-model="internalCustomer.name"
            :placeholder="$t('new-delivery.client-name-placeholder')"
          />
        </l-field>
        <l-field
          class="flex-1"
          :label="$t('new-delivery.client-last-name-label')"
        >
          <l-input
            v-model="internalCustomer.surname"
            :placeholder="$t('new-delivery.client-last-name-placeholder')"
          />
        </l-field>
      </div>

      <l-field
        class="flex-1"
        :label="$t('new-delivery.client-phone-label')"
        mandatory
      >
        <l-input
          v-model="internalCustomer.phoneNumber"
          type="tel"
          :placeholder="$t('new-delivery.client-phone-placeholder')"
          :wrong-value="phoneError"
        />
      </l-field>

      <l-field class="flex-1" :label="$t('new-delivery.client-email-label')">
        <l-input
          v-model="internalCustomer.email"
          type="email"
          :placeholder="$t('new-delivery.client-email-placeholder')"
        />
      </l-field>

      <l-field class="flex-1" :label="$t('new-delivery.internal-note')">
        <l-input v-model="internalCustomer.internalNote" type="textarea" />
      </l-field>

      <div class="flex justify-end mt-4 mb-10 gap-4">
        <l-button
          type="secondary"
          size="small"
          class="w-32"
          :disabled="!isCustomerFilled"
          @click="cancelCustomerEdit"
        >
          {{ $t('ctas.cancel') }}
        </l-button>
        <l-button
          size="small"
          class="w-32"
          :disabled="!isCustomerFilled"
          @click="switchToPreview"
        >
          {{ $t('ctas.apply') }}
        </l-button>
      </div>
    </div>

    <div v-else>
      <div class="flex mb-6">
        <div class="flex flex-1 items-center gap-3 min-w-0">
          <div
            class="h-16 w-16 rounded-full bg-r-400 font-body text-2xl text-n-0 flex items-center justify-center uppercase"
          >
            {{ initialName }}
          </div>
          <div class="flex flex-col flex-1 min-w-0">
            <div
              class="font-heading font-bold text-2xl text-ellipsis overflow-hidden whitespace-nowrap capitalize"
            >
              {{ internalCustomer.name }}
              {{ internalCustomer.surname }}
            </div>
            <div
              v-if="
                config.organizationConfig.promotions && internalCustomer.points
              "
              class="flex items-center gap-1 text-g-500"
            >
              <icon name="medal" />
              <div class="font-heading font-bold">
                {{ $n(internalCustomer.points.actualPoints ?? 0, 'decimal') }}
              </div>
            </div>
          </div>
        </div>
        <div class="ml-4">
          <l-button size="small" icon="pen" @click="startCustomerEdit" />
        </div>
      </div>

      <div class="flex mb-4">
        <div class="flex flex-col font-body text-sm flex-1">
          <div class="text-n-400">
            {{ $t('new-delivery.client-email-label') }}
          </div>
          <div class="text-n-0">{{ internalCustomer.email || '-' }}</div>
        </div>
        <div class="flex flex-col font-body text-sm flex-1">
          <div class="text-n-400">
            {{ $t('new-delivery.client-phone-label') }}
          </div>
          <div class="text-n-0">
            {{ internalCustomer.phoneNumber || '-' }}
          </div>
        </div>
      </div>
      <div class="flex flex-col font-body text-sm">
        <div class="text-n-400">
          {{ $t('new-delivery.internal-note') }}
        </div>
        <div class="text-n-0">{{ internalCustomer.internalNote || '-' }}</div>
      </div>
    </div>

    <div ref="delivery" class="mt-6">
      <delivery-address-picker
        v-if="showDelivery"
        v-model="internalCustomer.addresses"
        :hide-new-address-cta="isEdit"
        :address-missing="deliveryError"
        @delivery-address-selected="setDeliveryAddressOnTab"
      />
    </div>

    <div v-if="lastCustomerTabs && lastCustomerTabs.length > 0" class="mt-6">
      <div class="font-heading font-medium text-base mb-4">
        {{ $t('new-delivery.last-customer-tabs') }}
      </div>
      <div v-if="lastCustomerTabs.length === 0">
        <EmptyCase
          item-translation-key="new-delivery.last-customer-tabs-empty"
          type="empty"
        />
      </div>

      <div v-else class="flex overflow-x-auto">
        <div
          v-for="tab in lastCustomerTabs"
          :key="tab.id"
          class="shrink-0 bg-n-700 rounded-xl border border-n-600 mr-6 h-56 w-56 flex flex-col"
          :class="{
            'border-v-300': selectedLastTabsId === tab.id
          }"
          @click="setTheLastTab(tab)"
        >
          <div class="px-4 pt-6 flex flex-col min-h-0">
            <div class="flex justify-between font-heading">
              <div class="font-medium">
                {{ $d(tab.creationTime, 'day') }}
              </div>
              <div class="font-semibold">
                {{ $d(tab.creationTime, 'time') }}
              </div>
            </div>

            <div class="overflow-y-auto min-h-0 flex flex-col text-sm">
              <div
                v-for="product in tab.products"
                :key="product.id"
                class="flex gap-2 h-8 items-center shrink-0"
              >
                <div class="w-5 text-center">
                  {{ product.quantity }}
                </div>
                <div class="">
                  {{ product.name }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="px-4 py-4 flex justify-between items-center shrink-0 border-t border-n-600 mt-auto"
          >
            <div class="">
              {{ $t('new-delivery.total') }}
            </div>
            <div class="font-heading font-bold text-xl h-6">
              {{ $filters.currency(tab.total) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </l-modal>

  <l-modal
    v-if="!showCustomerEditor"
    :title="$t('new-delivery.tab-details')"
    :button-enabled="!isInvalidDeliveryForm"
    :button-text="isEdit ? $t('ctas.update') : $t('new-delivery.cta-create')"
    @action="
      isEdit
        ? updateTabAndUpdateUserIfNeeded()
        : createNewTabAndUpdateUserIfNeeded()
    "
    @close="$emit('close')"
  >
    <div ref="seats" class="flex">
      <l-field
        v-if="!isEdit"
        class="flex-1"
        :label="$t('new-delivery.client-number-label')"
        :mandatory="seatsRequired"
      >
        <l-select
          v-model="internalTab.seats"
          :options="numberOfPeople"
          :error="dinersError"
          :allow-remove="!seatsRequired"
        />
      </l-field>
      <l-field
        class="flex-1"
        :class="{ 'ml-4': !isEdit }"
        :label="$t('new-delivery.pickup-label')"
      >
        <day-time-picker
          v-model="internalTab.schedulingTime"
          :placeholder="$t('new-delivery.schedule-now')"
          :only-future="true"
          :default-date="moment().toISOString()"
          :timezone="config.workingTime.timezone"
          @click.stop
          @update:model-value="schedulingTimeEdited = true"
        />
      </l-field>
    </div>

    <l-field class="flex-1 mt-4" :label="$t('new-delivery.customer-notes')">
      <l-input
        v-model="internalTab.customerNote"
        type="textarea"
        :max-length="255"
      />
    </l-field>
    <l-field class="flex-1" :label="$t('new-delivery.kitchen-notes')">
      <l-input
        v-model="internalTab.kitchenNote"
        type="textarea"
        :max-length="255"
      />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import { useBillingStore } from '@/store/billing.js'
import { storeToRefs } from 'pinia'
import sync from '@/sync/service'
import {
  LInput,
  LField,
  LSelect,
  LModal,
  LButton,
  Icon,
  EmptyCase,
  DayTimePicker
} from '@last/core-ui/paprika'
import DeliveryAddressPicker from '@/components/tabs/DeliveryAddressPicker.vue'
import TicketPrinter from '@/ticketPrinter.js'
import moment from 'moment'
import { ref, computed, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { Address } from '@/types'

const props = withDefaults(
  defineProps<{
    newTab: any
    isEdit?: boolean
    customer: any
    delivery?: Address
    lastCustomerTabs?: any[]
    isOwnDelivery: boolean
  }>(),
  {
    isEdit: false,
    customer: () => ({}),
    delivery: undefined,
    lastCustomerTabs: undefined,
    isOwnDelivery: false
  }
)

const emit = defineEmits(['back', 'tabCreated', 'close'])

const { t } = useI18n()
const router = useRouter()
const configStore = useConfigStore()
const tabsStore = useTabsStore()
const billingStore = useBillingStore()

const { config } = storeToRefs(configStore)
const { removeTabBills, generatePendingBill, openTabWithCustomer } = tabsStore
const { getTabPreparationMinutes, getBills, getGlobalDiscountByTabId } =
  storeToRefs(tabsStore)
const { getCurrentBill } = storeToRefs(billingStore)

const selectedLastTabsId = ref(null)
const edit = ref(false)
const internalCustomer = ref<any>({})
const temporallyCustomer = ref({})
const internalDelivery = ref<Address | {}>({})
const internalTab = ref<any>({})
const schedulingTimeEdited = ref(false)
const showCustomerEditor = ref(true)
// Form validation
const deliveryError = ref(false)
const dinersError = ref(false)
const phoneError = ref(false)

const billNeedsRegeneration = computed(() => {
  return (
    props.customer.name !== internalCustomer.value.name ||
    props.customer.surname !== internalCustomer.value.surname ||
    props.customer.phoneNumber !== internalCustomer.value.phoneNumber ||
    props.customer.email !== internalCustomer.value.email ||
    props.customer.addresses[0].address !==
      internalCustomer.value.addresses[0].address ||
    props.customer.addresses[0].details !==
      internalCustomer.value.addresses[0].details ||
    props.customer.addresses[0].postalCode !==
      internalCustomer.value.addresses[0].postalCode
  )
})

const getPickupTime = computed(() => {
  let preparationMinutes = getTabPreparationMinutes.value(
    internalTab.value.virtualBrandId
  )
  let asap = moment().add(preparationMinutes || 15, 'minutes')
  let pickupTime = internalTab.value.schedulingTime
    ? ['delivery', 'ownDelivery'].includes(internalTab.value.pickupType)
      ? moment(internalTab.value.schedulingTime).subtract(15, 'minutes')
      : moment(internalTab.value.schedulingTime)
    : asap
  if (asap > pickupTime) {
    pickupTime = asap
  }
  return pickupTime.toDate()
})

const showDelivery = computed(() => {
  return ['delivery', 'ownDelivery'].includes(internalTab.value.pickupType)
})

const isCustomerFilled = computed(() => {
  return (
    internalCustomer.value &&
    internalCustomer.value.name &&
    internalCustomer.value.surname &&
    internalCustomer.value.phoneNumber
  )
})

const isValidUserForm = computed(() => {
  return showDelivery.value
    ? internalCustomer.value.addresses &&
        internalCustomer.value.addresses.length > 0
    : true && props.isOwnDelivery
      ? !!internalCustomer.value.phoneNumber
      : true
})

const isInvalidDeliveryForm = computed(() => {
  return seatsRequired.value && !internalTab.value.seats
})

const seatsRequired = computed(() => {
  return (
    !!props.isOwnDelivery && !!config.value.organizationConfig.seatsRequired
  )
})

const numberOfPeople = computed(() => {
  let numberOfPeople = []
  for (let i = 0; i < 20; i++) {
    numberOfPeople[i] = {
      label: `${i < 9 ? '0' : ''}${i + 1} ${t('new-delivery.client-number')}`,
      value: i + 1
    }
  }
  return numberOfPeople
})

const initialName = computed(() => {
  if (!internalCustomer.value) return ''
  const fullName = `${internalCustomer.value.name} ${props.customer.surname}`
  let initials = fullName
    .split(/[.,/ -]/)
    .map(n => n[0])
    .join('')
    .slice(0, 2)
  if (initials.length < 2) {
    return fullName.slice(0, 2)
  } else {
    return initials
  }
})

onMounted(() => {
  nextTick(setBasicInformationSection)
  internalCustomer.value = { ...props.customer }
  internalDelivery.value = { ...props.delivery }
  internalTab.value = { ...internalTab.value, ...props.newTab }
  if (!props.isEdit) internalTab.value.id = uuid()
})

function startCustomerEdit() {
  temporallyCustomer.value = { ...internalCustomer.value }
  edit.value = true
}

function cancelCustomerEdit() {
  internalCustomer.value = {
    ...internalCustomer.value,
    ...temporallyCustomer.value
  }
  edit.value = false
}

function setDeliveryAddressOnTab(address: Address) {
  internalDelivery.value = { ...internalDelivery.value, ...address }
}

function setBasicInformationSection() {
  if (!isCustomerFilled.value) edit.value = true
}

function checkUserForm() {
  deliveryError.value = false
  phoneError.value = false
  if (
    showDelivery.value &&
    (!internalCustomer.value.addresses ||
      internalCustomer.value.addresses.length < 1)
  ) {
    deliveryError.value = true
  }
  if (props.isOwnDelivery && !internalCustomer.value.phoneNumber) {
    phoneError.value = true
  }
  if (isValidUserForm.value) {
    showCustomerEditor.value = false
  }
}

function checkDeliveryDetails() {
  dinersError.value = false
  if (seatsRequired.value && !internalTab.value.seats) {
    dinersError.value = false
  }
}

async function createNewTabAndUpdateUserIfNeeded() {
  if (isInvalidDeliveryForm.value) {
    checkDeliveryDetails()
    return
  }
  let { isCreated, addresses, points, ...customer } = internalCustomer.value
  const { address, details, postalCode } = internalDelivery.value as Address

  if (!props.isEdit) customer.addresses = addresses

  customer.source = internalTab.value.source
  customer = {
    ...customer,
    address,
    addressDetails: details,
    postalCode
  }
  if (!isCreated) {
    customer.id = uuid()
  }

  internalTab.value.customerId = customer.id
  internalTab.value.name = internalTab.value.name || customer.name
  let tab = {
    ...internalTab.value,
    deliveryOrder: {
      ...internalTab.value.deliveryOrder,
      ...internalDelivery.value,
      addressDetails: internalDelivery.value.details,
      source: internalTab.value.source,
      status: 'CREATED',
      deliveryOrderStatuses: [
        {
          status: 'CREATED',
          creationTime: new Date()
        }
      ],
      pickupTime: getPickupTime.value
    },
    customerInfo: internalCustomer.value
  }
  let tabId = await openTabWithCustomer({
    tableId: null,
    tab,
    customer
  })
  emit('tabCreated', tabId)
  emit('close')
  router.push({
    name: 'orderManagement',
    params: { tabId: internalTab.value.id }
  })
}

function updateTabAndUpdateUserIfNeeded() {
  if (isInvalidDeliveryForm.value) {
    checkDeliveryDetails()
    return
  }

  internalTab.value.customerInfo = internalCustomer.value
  internalTab.value.deliveryOrder = {
    ...internalDelivery.value,
    addressDetails: internalDelivery.value.details
  }

  if (schedulingTimeEdited.value) {
    sync.record('deliveryOrderStatusUpdated', {
      tabId: internalTab.value.id,
      newStatus: 'CREATED',
      date: new Date()
    })
    internalTab.value = {
      ...internalTab.value,
      activationTime: null,
      deliveryOrder: {
        ...internalTab.value.deliveryOrder,
        pickupTime: getPickupTime.value
      }
    }
  }
  sync.record('tabDetailsUpdated', internalTab.value)
  if (billNeedsRegeneration.value) {
    removeTabBills(internalTab.value.id)
    printBill()
  }
  emit('close')
}

function printBill() {
  generatePendingBill({
    tabId: internalTab.value.id,
    bill: getCurrentBill.value({
      tabId: internalTab.value.id,
      discount: getGlobalDiscountByTabId.value(internalTab.value.id), // FIXME
      selectedProductIds: internalTab.value.products,
      includeAlreadyBilled: true
    })
  })
  let bills = getBills.value(internalTab.value.id)
  bills.forEach(TicketPrinter.printBill)
}

function switchToPreview() {
  if (!isCustomerFilled.value) return
  edit.value = false
}

function setTheSharedProductsIntoTheNewTab(tab: any) {
  internalTab.value.shared = tab.shared
    .filter(product => product.price > 0)
    .map(product => {
      let mappedProduct = {
        ...product,
        id: uuid(),
        tab: internalTab.value.id,
        tabId: internalTab.value.id,
        modifiers: (product.modifiers || []).map(modifier => ({
          ...modifier,
          id: uuid()
        }))
      }
      if (product.comboProducts) {
        mappedProduct.comboProducts = product.comboProducts.map(
          comboProduct => {
            return {
              ...comboProduct,
              id: uuid(),
              modifiers: (comboProduct.modifiers || []).map(modifier => ({
                ...modifier,
                id: uuid()
              }))
            }
          }
        )
      }
      return mappedProduct
    })
}

function setTheLastTab(tab: any) {
  setTheSharedProductsIntoTheNewTab(tab)
  internalTab.value.kitchenNote = tab.kitchenNote
  internalTab.value.customerNote = tab.customerNote
  selectedLastTabsId.value = tab.id
}
</script>
