<template>
  <tippy
    ref="contextMenu"
    :interactive="true"
    :arrow="false"
    trigger="manual"
    theme="contextmenu"
    :placement="placement"
    :duration="100"
    :append-to="appendToParent ? 'parent' : () => appendTarget"
    @hidden="() => (open = false)"
  >
    <slot />
    <template #content>
      <div
        class="rounded-xl py-2 mt-2 z-20 overflow-y-scroll min-w-48 text-n-600 bg-n-0 dark:bg-n-800 dark:text-white dark:border-n-700"
        :class="[
          fullscreen
            ? 'w-screen h-dvh fixed left-0 right-0'
            : 'max-w-80 max-h-80 border border-n-100'
        ]"
      >
        <div class="px-4 py-3 text-sm">
          <l-search-bar
            v-if="search"
            v-model="query"
            :debounce="200"
            class="mb-2"
          />
          <div v-for="option in filteredOptions" :key="option.value">
            <dropdown-option
              :model-value="models[option.value]"
              :option="option"
              :multiselect="props.multiselect"
              :has-checkbox="hasCheckbox"
              :mark-selected="markSelected"
              @update:model-value="value => selectOption(option, value)"
            />
          </div>
        </div>
      </div>
    </template>
  </tippy>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { ref, computed, defineProps, defineModel, watch, onMounted } from 'vue'
import { LSearchBar } from '@last/core-ui/paprika'
import DropdownOption from './DropdownOption.vue'
import type { Option, Model } from './types'
import utils from '@last/core/src/lastUtils.js'
import { Tippy, type TippyOptions } from 'vue-tippy'

type Props = {
  options: Option[]
  allowRemove?: boolean
  search?: boolean
  multiselect?: boolean
  fullscreen?: boolean
  appendToParent?: boolean
  markSelected?: boolean
  placement?: TippyOptions['placement']
}

const props = withDefaults(defineProps<Props>(), {
  appendToParent: false,
  markSelected: true,
  placement: 'bottom-start'
})

const appendTarget = document.body

const selected = defineModel<string[]>({
  default: [],
  required: false
})

const open = defineModel<boolean>('open')

const models = computed(() => {
  return props.options.reduce(
    (acc, option) => {
      acc[option.value] = {
        value: option.value,
        selected: selected.value.includes(option.value),
        children: option.children
          ? option.children.map(child => ({
              value: child.value,
              selected: selected.value.includes(child.value)
            }))
          : []
      }
      return acc
    },
    {} as Record<string, Model>
  )
})

const query = ref('')

const filteredOptions = computed(() => {
  if (!query.value) return props.options
  return props.options.flatMap(option => {
    if (utils.fuzzy(option.label, query.value)) return [option]
    if (!option.children) return []
    const filteredChildren = option.children.filter(child =>
      utils.fuzzy(child.label, query.value)
    )
    return filteredChildren.length
      ? [{ ...option, children: filteredChildren }]
      : []
  })
})

const container = ref(null)

onClickOutside(container, () => {
  open.value = false
})

const hasCheckbox = computed<boolean>(() => {
  return props.options.some(option => {
    return option.checkbox || option.children?.some(child => child.checkbox)
  })
})

const selectOption = (option: Option, selection: Model) => {
  let optionValues = [
    option.value,
    ...(option.children?.map(child => child.value) || [])
  ]
  let selectedValues: string[] = selection.selected ? [selection.value] : []
  for (let child of selection.children ?? []) {
    if (child.selected) selectedValues.push(child.value)
  }
  if (!props.multiselect) {
    selected.value = selectedValues
    open.value = false
  } else {
    let newSelected = selected.value
      .filter(value => !optionValues.includes(value))
      .concat(selectedValues)
    selected.value = newSelected
  }
}

const contextMenu = ref<typeof Tippy>()

watch(open, value => {
  if (value) {
    contextMenu.value?.show()
  } else {
    contextMenu.value?.hide()
  }
})

onMounted(() => {
  if (open.value) {
    contextMenu.value?.show()
  }
})
</script>

<style>
@import 'tippy.js/dist/tippy.css';

.tippy-box[data-theme~='contextmenu'] {
  background: none !important;
}

.tippy-content[data-theme~='contextmenu'] {
  padding: 0;
}

.tippy-box[data-theme~='contextmenu'][data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
