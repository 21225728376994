<template>
  <div
    class="absolute top-0 left-0 z-20 flex flex-col justify-start w-screen h-screen p-5 overflow-y-scroll scrolling-touch bg-n-900/80 backdrop-blur-sm"
  >
    <div
      class="rounded-2xl overflow-hidden flex flex-col h-full bg-n-800 px-11 pt-7 pb-12 relative"
    >
      <div class="flex items-center justify-center absolute top-4 right-4">
        <l-button
          size="small"
          icon="close"
          type="text"
          color="white"
          @click="$emit('close')"
        />
      </div>
      <div
        class="text-n-0 font-heading text-xl font-bold flex flex-col gap-3 mb-6"
      >
        <div v-if="!deliveries">
          {{ $t('tabs.stuart-not-assigned') }}
        </div>
        <div v-else-if="!hasCourier">
          {{ $t('tabs.courier-not-assigned') }}
        </div>
        <div v-else>
          <div class="text-2xl font-bold">
            {{ $t('tabs.delivery') }}
          </div>
          <div
            v-if="currentTabDelivery.etaToDestination"
            class="font-body text-sm font-normal"
          >
            {{ $t('tabs.estimated-time-of-delivery') }}
            {{ $d(currentTabDelivery.etaToDestination, 'time') }}
          </div>
        </div>
      </div>

      <div
        class="rounded-2xl overflow-hidden relative flex-1 w-full"
        @click.stop
      >
        <google-maps-tracker
          v-if="job"
          :pickups="pickups"
          :deliveries="deliveries"
          :courier="job.courier"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { LButton } from '@last/core-ui/paprika'
import { computed } from 'vue'
import GoogleMapsTracker from '../GoogleMapsTracker.vue'

const props = defineProps<{
  job: any
  tab: any
}>()

defineEmits(['close'])

const authStore = useAuthStore()
const { locationCoordinates } = storeToRefs(authStore)

const pickups = computed(() => {
  return [locationCoordinates.value]
})

const deliveries = computed(() => {
  return props.job?.deliveries
})

const hasCourier = computed(() => {
  return !!props.job?.courier?.latitude
})

const currentTabDelivery = computed(() => {
  return props.job?.deliveries[0]
})
</script>
