<template>
  <on-click-outside @trigger="emit('click-outside')">
    <div class="flex items-center text-n-0">
      <div
        class="rounded-full w-6 h-6 p-1 bg-v-300"
        :class="[{ 'opacity-50': quantity === min }]"
        @click.stop="subtract"
      >
        <icon name="minus" class="w-full h-full text-n-0" />
      </div>
      <slot name="default" :quantity="quantity">
        <div class="w-7 text-center">{{ quantity }}</div>
      </slot>
      <div
        class="rounded-full w-6 h-6 p-1 bg-v-300"
        :class="[{ 'opacity-50': isMaxed }]"
        @click.stop="add"
      >
        <icon name="plus" class="w-full h-full text-n-0" />
      </div>
    </div>
  </on-click-outside>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import { Icon } from '@last/core-ui/paprika'
import { OnClickOutside } from '@vueuse/components'

const props = withDefaults(
  defineProps<{
    quantity: number
    min?: number
    isMaxed?: boolean
    step?: number
  }>(),
  {
    min: 1,
    isMaxed: false,
    step: 1
  }
)

const emit = defineEmits<{
  (event: 'update:quantity', quantity: number): void
  (event: 'click-outside'): void
}>()

function add() {
  if (!props.isMaxed) {
    emit('update:quantity', props.quantity + props.step)
  }
}

function subtract() {
  if (props.quantity > props.min) {
    emit('update:quantity', props.quantity - props.step)
  }
}
</script>
