<template>
  <l-button
    icon="discount"
    :size="size"
    :disabled="disabled"
    color="green"
    @click="editDiscount"
  >
    {{ $t('bill-discount.button') }}
    <span v-if="discount && discount.amount">{{
      discount.type === 'percentage'
        ? `${discount.amount}%`
        : $filters.currency(discount.amount)
    }}</span>
  </l-button>
  <l-modal
    :title="$t('bill-discount.title-plural')"
    :button-text="$t('bill-discount.save')"
    :button-enabled="!wrongAmount"
    :open="showModal"
    @action="save"
    @close="close"
  >
    <div class="flex flex-row font-title text-xl font-bold">
      {{ $t('bill-discount.select-discount-type') }}
    </div>
    <l-option-selector v-model="selectedOption" :options="discountTypes" />
    <div v-if="selectedOption === 'promotion'">
      <div v-if="promotionsWithoutPoints.length > 0" class="mb-8">
        <div class="flex flex-row font-title text-xl font-bold">
          {{ $t('bill-discount.no-points-discounts') }}
        </div>
        <div
          class="flex items-center mt-3 overflow-scroll scrolling-touch gap-3"
        >
          <promotion-item
            v-for="promotion in promotionsWithoutPoints"
            :key="promotion.id"
            :promotion="promotion"
            :selected="editingDiscount.promotionId === promotion.id"
            @click="promotionSelected(promotion)"
          />
        </div>
      </div>
      <div v-if="promotionsWithPoints.length > 0">
        <div class="flex flex-row font-title text-xl font-bold">
          <div>{{ $t('bill-discount.points-discounts') }} -</div>
          <div class="pl-1 text-g-400">
            {{ $t('bill-discount.available-points') }}:
            {{ customerPoints }}
          </div>
        </div>
        <div
          class="flex items-center mt-3 overflow-scroll scrolling-touch gap-3"
        >
          <promotion-item
            v-for="promotion in promotionsWithPoints"
            :key="promotion.id"
            :promotion="promotion"
            :selected="editingDiscount.promotionId === promotion.id"
            @click="promotionSelected(promotion)"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <l-field :label="$t('bill-discount.discount-to-apply')" class="pt-2">
        <l-input
          v-if="editingDiscount.type === 'currency'"
          v-model.currency="editingDiscount.amount"
          type="number"
          :min="0"
          :icon="discountIcon"
        />
        <l-input
          v-else
          v-model.number="editingDiscount.amount"
          type="number"
          :min="0"
          :max="100"
          :icon="discountIcon"
        />
      </l-field>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, defineModel } from 'vue'
import { storeToRefs } from 'pinia'
import moment from 'moment'
import {
  LModal,
  LInput,
  LOptionSelector,
  LField,
  LButton
} from '@last/core-ui/paprika'
import api from '@/api'
import PromotionFilter from '@last/core/src/PromotionFilter.js'
import { useAuthStore } from '@/store/auth'
import { usePromotionsStore } from '@/store/promotions'
import { useConfigStore } from '@/store/config'
import { useTabs } from '@/composables/useTabs'
import type { Discount, Promotion } from '@/types'
import { useI18n } from 'vue-i18n'
import PromotionItem from './PromotionItem.vue'
import { watch } from 'vue'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'

interface Props {
  originalTotal: number
  disabled?: boolean
  tabId: string
  size?: 'small' | 'medium'
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  size: 'medium'
})

const discount = defineModel<Discount>('discount')

const emit = defineEmits(['close', 'cancel'])

const { t } = useI18n()
const authStore = useAuthStore()
const promotionsStore = usePromotionsStore()
const configStore = useConfigStore()

const { locationId } = storeToRefs(authStore)
const { getPromotionsWithPoints, getPromotionsWithoutPoints } =
  storeToRefs(promotionsStore)
const { currencyIcon } = storeToRefs(configStore)

const tabId = computed(() => props.tabId)
const { tab, customerId, total } = useTabs(tabId)

const showModal = ref(false)
const customerPoints = ref(0)
const editingDiscount = ref<Discount>({
  type: 'percentage',
  amount: 0,
  global: true
})
const customerRestrictedPromotions = ref<Promotion[]>([])
const customerUsedPromotions = ref<Promotion[]>([])
const selectedOption = ref<string>('percentage')

const wrongAmount = computed(() => {
  if (editingDiscount.value.type === 'currency') {
    if (editingDiscount.value.amount > props.originalTotal) {
      return true
    }
  } else if (editingDiscount.value.type === 'percentage') {
    let amount = parseInt(String(editingDiscount.value.amount))
    if (amount < 0 || amount > 100 || isNaN(amount)) return true
  }
  return false
})

const promotionsWithPoints = computed(() => {
  return getPromotionsWithPoints.value
    .concat(customerRestrictedPromotions.value)
    .filter(promotion => {
      return (
        checkIfPromotionCanBeShown(promotion) &&
        promotion.pointsExpense &&
        promotion.pointsExpense <= customerPoints.value
      )
    })
})

const promotionsWithoutPoints = computed(() => {
  return getPromotionsWithoutPoints.value
    .concat(customerRestrictedPromotions.value)
    .filter(promotion => {
      return checkIfPromotionCanBeShown(promotion)
    })
})

const discountTypes = computed(() => {
  let types: Option[] = [
    {
      icon: 'money',
      value: 'currency',
      label: t('product-discount.cash')
    },
    {
      icon: 'percentage',
      value: 'percentage',
      label: t('product-discount.percentage')
    }
  ]
  if (
    promotionsWithPoints.value.length > 0 ||
    promotionsWithoutPoints.value.length > 0
  ) {
    types.push({
      value: 'promotion',
      label: t('bill-discount.promotion')
    })
  }
  return types
})

function close() {
  emit('close')
  showModal.value = false
}

function promotionSelected(promotion: Promotion) {
  if (editingDiscount.value.promotionId === promotion.id) {
    editingDiscount.value = {
      type: 'percentage',
      amount: 0,
      global: true
    }
  } else {
    editingDiscount.value = {
      type: promotion.discountType,
      amount: promotion.discountAmount,
      promotionId: promotion.id,
      pointsExpense: promotion.pointsExpense,
      promotionName: promotion.name,
      freeDelivery: promotion.freeDelivery,
      global: true
    }
  }
}

function editDiscount() {
  if (discount.value) {
    if (discount.value.promotionId) {
      selectedOption.value = 'promotion'
    } else {
      selectedOption.value = discount.value.type
    }
    editingDiscount.value = discount.value
  }
  showModal.value = true
}

function save() {
  if (!editingDiscount.value.amount) {
    editingDiscount.value.amount = 0
  }
  discount.value = {
    ...editingDiscount.value
  }
  showModal.value = false
}

function checkIfPromotionCanBeShown(promotion: Promotion) {
  const isNotProductOrCategory = !promotion.products && !promotion.categories

  let isCorrectAmount = true
  if (promotion.discountType === 'currency') {
    isCorrectAmount = promotion.discountAmount <= props.originalTotal
  }
  let isCorrectType = ['currency', 'percentage'].includes(
    promotion.discountType
  )

  let today = moment().locale('en').format('dddd').toLowerCase()

  return (
    isNotProductOrCategory &&
    isCorrectType &&
    isCorrectAmount &&
    new PromotionFilter(
      total.value,
      locationId.value,
      today,
      tab.value.pickupType,
      moment().format(),
      customerId.value,
      customerUsedPromotions.value
    ).isPromotionValid(promotion)
  )
}

onMounted(async () => {
  if (customerId.value) {
    let response = await api.get(`/customer/${customerId.value}/promotions`)
    customerPoints.value = response.data.points
    customerRestrictedPromotions.value = response.data.customerPromotions
    customerUsedPromotions.value = response.data.customerUsedPromotions
  }
})

const discountIcon = computed(() => {
  if (editingDiscount.value.type === 'percentage') {
    return 'percentage'
  }
  return `currencies/${currencyIcon.value}`
})

watch(
  selectedOption,
  () => {
    if (selectedOption.value !== 'promotion') {
      editingDiscount.value = {
        type: selectedOption.value,
        amount: 0,
        global: true
      }
    }
  },
  { immediate: true }
)
</script>
