<template>
  <div class="bg-n-600 rounded-xl flex items-center h-14 p-3">
    <div class="flex-none">
      <div
        class="rounded-full w-8 h-8 flex items-center justify-center mr-2"
        :style="{
          background: background,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderColor: borderColor
        }"
      >
        <div
          v-if="!hasBackgroundImage"
          :style="{
            color: getTextColor(item.color)
          }"
          class="text-sm font-normal uppercase"
        >
          {{ shortName }}
        </div>
      </div>
    </div>
    <div class="flex-1 text-n-10 text-sm mr-2 line-clamp-2">
      {{ item.name }}
    </div>
    <div class="flex-none">
      <l-switch
        :model-value="item.enabled"
        size="medium"
        @update:model-value="stockStore.toggleItem(item, $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { StockItem } from '@/types'
import { LSwitch } from '@last/core-ui/paprika'
import { computed } from 'vue'
import { useStockStore } from '@/store/stock'
import images from '@/images'
import { storeToRefs } from 'pinia'
import { useConfigStore } from '@/store/config'
import utils from '@last/core/src/lastUtils'

type Props = {
  item: StockItem
}

const props = defineProps<Props>()

const stockStore = useStockStore()
const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const shortName = computed(() => {
  if (props.item.shortName) {
    return props.item.shortName
  }
  if (!props.item.name) return ''
  let initials = props.item.name
    .split(/[.,/ -]/)
    .map(n => n[0])
    .join('')
    .slice(0, 2)
  if (initials.length < 2) {
    return props.item.name.slice(0, 2)
  } else {
    return initials
  }
})

const background = computed(() => {
  if (config.value.showProductImages && !!props.item.imageId) {
    let image = images.getImageUrl(props.item.imageId, { width: 32 })
    return `url(${image})`
  } else {
    return props.item.color || '#363654'
  }
})

const hasBackgroundImage = computed(() => {
  return background.value.includes('url(', 0)
})

const borderColor = computed(() => {
  return utils.darken(props.item.color)
})

function getTextColor(hexcolor?: string) {
  if (!hexcolor) return 'white'
  let color = hexcolor.substr(1, 6)
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 200 ? '#302F66' : 'white'
}
</script>
