<template>
  <div
    class="flex flex-row items-center px-4 h-16 font-body text-base relative cursor-pointer text-n-0"
    :class="{
      'opacity-30': status === 'closed',
      'bg-n-700': isSelected
    }"
  >
    <div class="flex items-center w-1/4 xl:w-1/6">
      <l-checkbox
        v-if="choosingForShipment"
        :model-value="pickedForShipment"
        class="mr-3"
        @update:model-value="value => $emit('shipmentPickToggled', value)"
      />
      <tab-title :tab-id="tab.id" :class="{ 'ml-8': offset }" />
    </div>
    <div class="text-lg w-1/4">
      <div v-if="isCancelled || hasAlert" class="pr-4">
        <l-status v-if="isCancelled" status="error">
          {{ $t('tabs.cancelled') }}
        </l-status>
        <l-status v-if="hasAlert" status="pending" class="cursor-pointer">
          {{ $t('tabs.alert') }}
        </l-status>
      </div>
      <div
        v-else-if="!tab.open"
        class="uppercase font-bold text-xl flex items-center"
        :class="{
          'text-r-400': wrongClosedState,
          'text-g-500': !wrongClosedState
        }"
      >
        {{ preparationTime }}
        <span class="text-n-200 text-lg ml-4">{{ $t('tabs.closed') }}</span>
      </div>
      <div v-else-if="!tab.activationTime" class="uppercase">
        {{ $t('tabs.scheduled') }}
      </div>
      <div v-else class="text-xl flex gap-2 items-center" :class="stateColor">
        <div>{{ timeFromTab.minutes }}:{{ timeFromTab.seconds }}</div>
        <delivery-status
          v-if="tab.deliveryOrder"
          :tab-id="tab.id"
          class="ml-3"
        />
      </div>
    </div>
    <div v-if="rowSize !== 'small'" class="flex-1 flex flex-col">
      <div>
        {{ $d(tab.activationTime || tab.schedulingTime, 'day') }}
      </div>
      <div class="text-n-200">
        {{ $d(tab.activationTime || tab.schedulingTime, 'time') }}
      </div>
    </div>
    <div class="flex-1 flex-col">
      <div>
        {{ $d(deliveryTime, 'day') }}
      </div>
      <div class="text-n-200">{{ $d(deliveryTime, 'time') }}</div>
    </div>
    <div class="w-1/4 xl:w-1/5">
      <div class="w-10/12">
        {{ longAddressText }}
      </div>
    </div>
    <div
      v-if="tab.deliveryOrder && tab.deliveryOrder.courierName"
      class="flex-1"
    >
      {{ tab.deliveryOrder.courierName }}
    </div>
    <div
      v-else-if="
        tab.deliveryOrder &&
        tab.deliveryOrder.shipmentId &&
        tab.pickupType === 'ownDelivery'
      "
      class="flex-1"
    >
      {{ tab.deliveryOrder.shipment.provider || '' }}
    </div>
    <div
      v-else-if="tab.deliveryOrder && tab.deliveryOrder.shipmentCancelled"
      class="flex-1 text-r-300"
    >
      {{ $t('tabs.shipment-cancelled') }}
    </div>
    <div v-else class="flex-1" />
    <div v-if="rowSize === 'large'" class="flex-1">
      {{ tab.customerInfo ? tab.customerInfo.phoneNumber || '' : '' }}
    </div>
    <div
      v-if="
        isSelected && tab.pickupType === 'ownDelivery' && !choosingForShipment
      "
      class="absolute inset-y-0 right-0 items-center flex mr-3"
    >
      <icon name="external" class="text-r-300" @click="$emit('goToDetails')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment'
import { defineProps, defineEmits, computed, toRef } from 'vue'
import TabTitle from './TabTitle.vue'
import DeliveryStatus from './DeliveryStatus.vue'
import { LCheckbox, Icon, LStatus } from '@last/core-ui/paprika'
import { useTabs } from '@/composables/useTabs'

interface Props {
  tabId: string
  rowSize: string
  currentTime: Date
  isSelected: boolean
  choosingForShipment: boolean
  pickedForShipment: boolean
  offset?: boolean
}

const props = defineProps<Props>()

defineEmits(['shipmentPickToggled', 'goToDetails'])

const tabId = toRef(props, 'tabId')

const { tab, status, isCancelled, hasAlert } = useTabs(tabId.value)

const longAddressText = computed(() => {
  if (tab.value.deliveryOrder?.address && tab.value.deliveryOrder?.postalCode) {
    return `${tab.value.deliveryOrder.address} (${tab.value.deliveryOrder.postalCode})`
  } else if (tab.value.deliveryOrder?.address) {
    return tab.value.deliveryOrder.address
  }
  return ''
})

const deliveryTime = computed(() => {
  if (tab.value.schedulingTime) {
    return tab.value.schedulingTime
  } else {
    return moment(tab.value.activationTime).add(30, 'minutes').toISOString()
  }
})

const preparationTime = computed(() => {
  const secondsDiff = Math.round(
    moment(tab.value.closeTime).diff(moment(tab.value.activationTime)) / 1000
  )

  return `${addZero(Math.round(secondsDiff / 60))}:${addZero(secondsDiff % 60)}`
})

const stateColor = computed(() => {
  let activationTime = tab.value.activationTime
  let now = props.currentTime
  let status = tab.value.deliveryOrder?.status
  let statusTimes
  switch (status) {
    case 'KITCHEN':
      statusTimes = [10, 15]
      break
    case 'READY_TO_PICKUP':
      statusTimes = [13, 20]
      break
    case 'ON_DELIVERY':
      statusTimes = [28, 40]
      break
  }
  if (!statusTimes) {
    return 'text-v-300'
  } else {
    if (
      moment(now)
        .subtract(statusTimes[0], 'minutes')
        .isBefore(moment(activationTime))
    ) {
      return 'text-v-300'
    } else if (
      moment(now)
        .subtract(statusTimes[1], 'minutes')
        .isBefore(moment(activationTime))
    ) {
      return 'text-y-300'
    } else {
      return 'text-r-300'
    }
  }
})

const wrongClosedState = computed(() => {
  return moment(tab.value.closeTime)
    .subtract(30, 'minutes')
    .isAfter(moment(tab.value.activationTime))
})

const timeFromTab = computed(() => {
  let secondsDifference = Math.round(
    (props.currentTime.getTime() -
      new Date(tab.value.activationTime).getTime()) /
      1000
  )
  return {
    minutes: addZero(Math.floor(secondsDifference / 60)),
    seconds: addZero(secondsDifference % 60)
  }
})

const addZero = (number: number) => {
  return number < 10 ? '0' + number : number
}
</script>
