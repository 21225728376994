import api from '@/api'
import { Device, DeviceConfig } from '@/types/device'

export function getDevices(locationId: string): Promise<{ ids: string[] }> {
  const headers = {
    'Location-ID': locationId
  }
  return api
    .get<{ ids: string[] }>('/devices', {
      headers
    })
    .then(res => res.data)
}

export async function addDevice(device: Device): Promise<DeviceConfig> {
  return api
    .put<DeviceConfig>(`/devices/${device.id}`, { ...device, deleted: false })
    .then(res => res.data)
}
