import app from '@/app'
import { useAuthStore } from '@/store/auth'
import { logger } from '@/monitoring'

class Browser {
  constructor(config) {
    this.config = config
  }

  printImage(imageData) {
    return new Promise(resolve => {
      let auth = useAuthStore()
      const locationId = auth.locationId
      const demoLocationId = import.meta.env.VITE_APP_DEMO_LOCATION_ID
      const isDemoLocation = locationId === demoLocationId
      if (isDemoLocation) return resolve()

      logger.debug(`Virtual Print to: ${this.config.address}`)
      if (!app.isElectron) {
        var image = new Image()
        image.src = imageData
        var w = window.open('')
        w.document.write(image.outerHTML)
      }
      resolve()
    })
  }

  openCashDrawer() {
    logger.debug(`Open Drawer: ${this.config.address}`)
  }
}

export default Browser
