import SocketFactory from '@/drivers/socket.js'
import lastUtils from '@last/core/src/lastUtils.js'
import { useNotifications } from '@/composables/useNotifications'
import i18n from '@/i18n'
import { Buffer } from 'buffer'

class CashKeeper {
  init({ ip, port, number, inputAmountListener, totalAmountListener }) {
    if (this.initialized) {
      return
    }
    this.initialized = true
    this.charging = false
    this.cancelled = false
    this.charged = 0
    this.config = { ip, port, number }
    this.enabled = ip && port
    this.inputAmountListener = inputAmountListener
    this.totalAmountListener = totalAmountListener
  }

  async sendCommand(command) {
    if (!this.config) throw new Error('Cashlogy without config')
    if (!this.socket || this.socket?.closed) {
      if (!this.socket) this.socket = SocketFactory.getSocket()
      return new Promise((resolve, reject) => {
        let socket = this.socket
        this.resolve = resolve
        socket.open(
          this.config.ip,
          parseInt(this.config.port),
          () => {
            socket.onData(response => {
              let r = Buffer.from(response).toString()
              if (this.cancelled) {
                if (r.includes('$100|0')) {
                  this.cancelled = false
                }
              } else if (this.charging) {
                if (r.includes('$100|0')) {
                  this.charging = false
                } else if (r.includes('$107')) {
                  this.charged = parseInt(r.split('|')[1])
                } else if (r.includes('$110|37')) {
                  const { notifyError } = useNotifications()
                  notifyError({
                    title: i18n.global.t(
                      'cash-machine-status.insufficient-change'
                    )
                  })
                  throw new Error('Insufficient change')
                }
              }
              this.resolve(r)
            })
            socket.write(Buffer.from(command), () => {}, reject)
          },
          reject
        )
      })
    } else {
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.socket.write(Buffer.from(command), () => {}, reject)
      })
    }
  }

  async initialize() {
    let res = await this.sendCommand('$47#')
    let providedNumber = parseInt(res.split('|')[1])
    let accessNumber = providedNumber + (parseInt(this.config.number) || 100001)
    res = await this.sendCommand(`$57|${accessNumber}#`)
    res = await this.sendCommand('$39|0#')
  }

  async charge(amount) {
    if (this.cancelled || this.charging) return 0
    if (
      !this.socket ||
      this.socket.closed ||
      (await this.sendCommand(`$42|${amount}|1#`)).includes('$42|0|37')
    ) {
      await this.initialize()
      return this.charge(amount)
    }
    this.charging = true
    this.charged = 0
    while (this.charging && !this.cancelled) {
      this.inputAmountListener(this.charged)
      this.sendCommand('$79|0|0#')
      await lastUtils.sleep(500)
    }
    if (this.cancelled) return 0
    return amount
  }

  async payIn(amount) {
    return this.charge(amount)
  }

  async payOut(amount) {
    if (!this.socket || this.socket.closed) {
      await this.initialize()
      return this.payOut(amount)
    }
    let res = await this.sendCommand(`$29|${amount}|0#`)
    if (res.includes('$29|0|37')) {
      await this.initialize()
      return this.payOut(amount)
    }
    if (res.includes('$29|0')) {
      const { notifyError } = useNotifications()
      notifyError({
        title: i18n.global.t('cash-machine-status.insufficient-change')
      })
      throw new Error('Insufficient change')
    }
    return -amount
  }

  async getCurrentAmount() {
    return this.charged
  }

  async refreshTotalAmount() {
    this.totalAmountListener(await this.getCurrentAmount())
  }

  async cancel() {
    this.cancelled = true
    await this.sendCommand('$42|0|1#')
    while (this.cancelled) {
      this.sendCommand('$79|0|0#')
      await lastUtils.sleep(500)
    }
    this.charging = false
  }
}

export default new CashKeeper()
