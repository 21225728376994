<template>
  <div
    class="flex w-full relative h-12 sm:h-14 box-border transition-all duration-300"
  >
    <div
      class="transition-all duration-300 w-full overflow-hidden top-0 left-0 search-bar gap-4 items-center"
      :class="{ expanded, flex: expanded, hidden: !expanded }"
    >
      <l-search-bar ref="searchBar" v-model="localValue" :size="searchBarSize">
      </l-search-bar>
      <l-button
        v-if="showCollapseButton"
        type="text"
        icon="close"
        color="white"
        @click="collapse"
      >
      </l-button>
    </div>
    <div
      class="absolute top-0 left-0 transition-all duration-300 flex bg-n-700 rounded-xl justify-center items-center cursor-pointer"
      :class="{
        expanded,
        'sm:h-[3.3rem] h-[2.8rem] top-[0.0625rem] left-[0.0625rem] w-[3.125rem]':
          expanded,
        'h-12 sm:h-14 w-full': !expanded
      }"
      @click="expand"
    >
      <icon
        class="text-n-0 transition-all duration-300 ease"
        name="search"
        :size="iconSize"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { LSearchBar, LButton, Icon } from '@last/core-ui/paprika'
import {
  withDefaults,
  defineProps,
  ref,
  computed,
  onMounted,
  onUnmounted,
  nextTick
} from 'vue'
import app from '@/app'

const props = withDefaults(
  defineProps<{
    modelValue: string
    expanded?: boolean
  }>(),
  { expanded: false }
)

const searchBar = ref<InstanceType<typeof LSearchBar>>()
const showCollapseButton = ref(false)

const emit = defineEmits(['update:modelValue', 'update:expanded'])

const localValue = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const expand = () => {
  emit('update:expanded', true)
  setTimeout(() => {
    showCollapseButton.value = true
  }, 400)
  nextTick(() => {
    if (searchBar.value) {
      searchBar.value.$el.click()
    }
  })
}

const collapse = () => {
  emit('update:expanded', false)
  showCollapseButton.value = false
  emit('update:modelValue', '')
}

const iconSize = computed(() => (props.expanded ? 'small' : 'medium'))
const searchBarSize = computed(() => (app.isMobile ? 'medium' : 'large'))

const handleKeyDown = (event: KeyboardEvent) => {
  if ((event.key === 'f' || event.key === 'F') && event.metaKey) {
    event.preventDefault()
    expand()
  } else if (event.key === 'Escape') {
    event.preventDefault()
    collapse()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown)
})
</script>
