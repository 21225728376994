<template>
  <teleport to="#appRoot">
    <div class="fixed inset-0 bg-n-800">
      <full-screen
        :title="
          isMobile && previewOrderId
            ? $t('print-bills.bill')
            : $t('resend-kitchen-orders.title')
        "
        :show-back="!!previewOrderId"
        :show-close="!previewOrderId"
        @close="$emit('close')"
        @back="previewOrderId = null"
      >
        <div class="text-n-0 flex h-full p-4 gap-4">
          <div
            class="flex flex-col shrink grow-0 relative"
            :class="[
              isMobile ? 'w-full' : 'basis-80',
              isMobile && previewOrderId ? 'hidden' : ''
            ]"
          >
            <tab-title
              :tab-id="tab.id"
              class="pb-4 border-b border-n-700 mb-4"
            />
            <div class="overflow-y-auto flex-1">
              <div
                v-for="order in orders"
                :key="order.id"
                class="bg-n-700 px-4 py-6 font-heading font-medium text-base rounded-xl border border-transparent flex flex-col cursor-pointer mb-4"
                :class="{
                  'border-v-300': order.id === previewOrderId
                }"
                @click="previewOrderId = order.id"
              >
                <div class="flex gap-1 items-center justify-between h-6">
                  <div class="flex items-center gap-2">
                    <div class="text-g-500">
                      {{ $d(order.creationTime, 'time') }}
                    </div>
                    {{ order.description }}
                  </div>
                  <l-status
                    v-if="order.printable && !order.printed"
                    class="ml-auto"
                    status="error"
                  >
                    {{ $t('resend-kitchen-orders.error') }}
                  </l-status>
                </div>
                <div class="text-n-200 font-body text-sm">
                  {{ getOrderDetails(order) }}
                </div>
              </div>
            </div>
            <div class="mt-4 w-full">
              <l-button
                :disabled="!hasFailedOrders"
                class="w-full"
                @click="resendFailed()"
              >
                {{ $t('resend-kitchen-orders.resend-failed') }}
              </l-button>
            </div>
          </div>
          <div
            v-if="previewOrderId"
            class="flex flex-col sm:p-4 sm:bg-n-700 font-body text-xs flex-1 grow shrink rounded-lg sm:min-w-96 h-full relative"
          >
            <div class="overflow-y-auto flex-1">
              <div class="flex justify-center">
                <div class="flex-1 hidden sm:block"></div>
                <div v-if="canvasImage" class="bg-n-0 rounded-lg p-2">
                  <img :src="canvasImage" />
                </div>
                <div class="flex-1 min-w-44 hidden sm:block"></div>
              </div>
            </div>
            <div class="pt-4 sm:fixed bottom-8 right-8">
              <l-button
                class="w-full sm:w-auto"
                icon="printer"
                @click="resendOrder(previewOrderId)"
              >
                {{ $t('resend-kitchen-orders.resend') }}
              </l-button>
            </div>
          </div>
        </div>
      </full-screen>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import TicketPrinter from '@/ticketPrinter.js'
import KitchenOrderFormatter from '@/tickets/kitchenOrderFormatter.js'
import TicketGenerator from '@/tickets/generator.js'
import { LStatus, LButton } from '@last/core-ui/paprika'
import FullScreen from '../core/FullScreen.vue'
import { useTabsStore } from '@/store/tabs'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { onMounted, ref } from 'vue'
import { computed } from 'vue'
import { watch } from 'vue'
import { useTabs } from '@/composables/useTabs'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import TabTitle from '../tabs/TabTitle.vue'

type Props = {
  tabId: string
}

type PartialOrder = {
  id: string
  creationTime: Date
  description: string
  copies: number
  printed: boolean
  printable: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])

const tabsStore = useTabsStore()
const catalogStore = useCatalogStore()
const configStore = useConfigStore()

const { kitchenOrders } = storeToRefs(tabsStore)
const { getCatalogIdByTabVirtualBrandId } = catalogStore
const { catalogs } = storeToRefs(catalogStore)
const { config } = storeToRefs(configStore)

const { getKitchenOrder } = useKitchenOrders(props.tabId)
const { tab } = useTabs(props.tabId)

const previewOrderId = ref<string | null>(null)
const canvasImage = ref<string | null>(null)

const isMobile = useBreakpoints(breakpointsTailwind).smallerOrEqual('sm')

onMounted(() => {
  if (isMobile.value) return
  previewOrderId.value = orders.value[0].id
})

const hasFailedOrders = computed(() => {
  return (
    orders.value.filter(order => !order.printed && order.printable).length > 0
  )
})

const orders = computed(() => {
  let orders = tab.value.kitchenOrders.map(id => kitchenOrders.value[id])
  return Object.values(orders)
    .map(order => {
      return {
        id: order.id,
        creationTime: new Date(order.creationTime),
        description: order.printerName,
        copies: order.copies,
        printed: !!order.printedTime,
        printable: order.copies > 0
      } satisfies PartialOrder
    })
    .sort((a, b) => b.creationTime.getTime() - a.creationTime.getTime())
})

const previewPrinter = computed(() => {
  return previewOrderId.value && kitchenOrders.value[previewOrderId.value]
    ? config.value.printers[kitchenOrders.value[previewOrderId.value].printerId]
    : null
})

const previewOrder = computed(() => {
  if (!previewOrderId.value) return null
  const catalogId = getCatalogIdByTabVirtualBrandId(
    tab.value.virtualBrandId,
    tab.value.pickupType,
    tab.value.source
  )
  const sortedCourses = catalogs.value[catalogId!]?.courses || []
  return KitchenOrderFormatter.format(
    kitchenOrders.value[previewOrderId.value],
    sortedCourses
  )
})

watch(previewOrder, async () => {
  if (previewOrder.value) {
    const canvas = await TicketGenerator.kitchenOrder(
      previewOrder.value,
      previewPrinter.value
    )
    canvasImage.value = canvas.toDataURL('image/png')
  }
})

function resendOrder(orderId: string) {
  TicketPrinter.printKitchenOrder(getKitchenOrder(orderId))
  previewOrderId.value = null
}

function resendFailed() {
  orders.value
    .filter(order => !order.printed && order.printable)
    .forEach(order =>
      TicketPrinter.printKitchenOrder(getKitchenOrder(order.id))
    )
  emit('close')
}

function getOrderDetails(order: PartialOrder): string {
  const lastVersion =
    kitchenOrders.value[order.id].versions[
      kitchenOrders.value[order.id].versions.length - 1
    ]

  const calculations = lastVersion.products.reduce(
    (acc, item) => {
      if (!acc[item.course]) {
        acc[item.course] = 0
      }
      acc[item.course] = acc[item.course] + item.quantity
      return acc
    },
    {} as Record<string, number>
  )

  const result: string[] = []
  Object.entries(calculations).forEach(([key, value]) => {
    result.push(`${value} ${key}`)
  })

  return result.join(', ')
}
</script>
