<template>
  <div
    v-for="(method, index) in openMethods"
    :key="index"
    class="bg-n-600 rounded-xl min-h-[4.5rem] p-4 flex items-center mb-4"
    :class="{
      'bg-v-300': currentStep === index
    }"
    @click="$emit('step', index)"
  >
    <div class="text-n-0 flex flex-row items-start justify-between w-full">
      <div class="font-heading font-medium capitalize">
        {{ method.method }}
      </div>
      <div
        v-if="!config.blindTill"
        class="flex flex-col items-end gap-1 font-body text-sm text-n-0"
      >
        <template v-if="amounts[method.method] !== undefined">
          <div class="flex gap-2 items-center">
            <span class="text-xs text-n-200">
              {{ $t('end-shift.expected') }}
            </span>
            <span>{{ $filters.currency(method.currentAmount) }}</span>
          </div>
          <div class="flex gap-2 items-center">
            <span class="text-xs text-n-200">
              {{ $t('end-shift.declared') }}
            </span>
            <span>{{ $filters.currency(amounts[method.method]) }}</span>
          </div>
          <div class="flex gap-2 items-center">
            <span class="text-xs text-n-200">
              {{ $t('end-shift.difference') }}
            </span>
            <span
              class="font-heading text-xl font-bold"
              :class="
                amounts[method.method] - method.currentAmount === 0
                  ? 'text-g-500'
                  : 'text-r-500'
              "
            >
              {{
                $filters.currency(amounts[method.method] - method.currentAmount)
              }}
            </span>
          </div>
        </template>
        <div v-else class="flex gap-2 items-center">
          <span class="text-xs text-n-200">
            {{ $t('end-shift.expected') }}
          </span>
          <span class="font-heading text-xl font-bold">
            {{ $filters.currency(method.currentAmount) }}
          </span>
        </div>
      </div>
      <div
        v-else-if="amounts[method.method] !== undefined"
        class="font-heading text-xl font-bold text-g-500"
      >
        {{ $filters.currency(amounts[method.method]) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConfigStore } from '@/store/config'
import { OpenMethodWithCurrentAmount } from '@/types'
import { storeToRefs } from 'pinia'

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

defineProps<{
  openMethods: OpenMethodWithCurrentAmount[]
  amounts: Record<string, number>
  currentStep: number | null
}>()

defineEmits<{
  (e: 'step', index: number): void
}>()
</script>
