import api from '@/api'
import { Courier } from '@/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useCouriersStore = defineStore(
  'couriers',
  () => {
    const couriers = ref<Record<string, Courier>>({})

    async function refreshCouriers(inputCouriers?: Courier[]) {
      let updateCouriers = inputCouriers
      if (!updateCouriers) {
        updateCouriers = (await api.get('/couriers')).data
      }
      couriers.value = updateCouriers!.reduce(
        (res, courier) => {
          res[courier.id] = courier
          return res
        },
        {} as Record<string, Courier>
      )
    }

    return {
      couriers,
      refreshCouriers
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCouriersStore, import.meta.hot))
}
