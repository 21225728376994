<template>
  <div class="flex flex-row mb-4 gap-2">
    <category
      :key="initialAmount"
      class="flex-1 border border-n-600"
      :category="{
        name: currency(initialAmount),
        id: `${initialAmount}`
      }"
      :selected="initialAmount === actualAmount"
      @selected="$emit('change', initialAmount)"
    />
    <category
      v-for="amount of amounts"
      :key="amount"
      :index="amount"
      :selected="amount === actualAmount"
      class="flex-1 border border-n-600"
      :category="{
        name: currency(amount),
        id: `${amount}`
      }"
      @selected="$emit('change', amount)"
    />
  </div>
</template>

<script setup lang="ts">
import Category from '@/components/ordering/Category.vue'
import { useMoney } from '@/composables/useMoney'
import { computed } from 'vue'

type Props = {
  initialAmount: number
  actualAmount: number
  tipMode?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  initialAmount: 0,
  tipMode: false
})

const { currency, mostProbableHandedAmounts, mostProbableHandedTips } =
  useMoney()

defineEmits<{
  (e: 'change', value: number): void
}>()

const amounts = computed(() => {
  if (props.tipMode) {
    return mostProbableHandedTips(props.initialAmount / 100)
  } else {
    return mostProbableHandedAmounts(props.initialAmount / 100)
  }
})
</script>
