<template>
  <h2 class="text-n-0 text-2xl font-heading font-bold mb-6">
    {{ $t('login.title') }}
  </h2>

  <div>
    <l-field :label="$t('inputs.user-label')">
      <l-input
        v-model="email"
        type="text"
        :wrong-value="error"
        :placeholder="$t('inputs.user-placeholder')"
      />
    </l-field>
    <l-field :label="$t('inputs.password-label')">
      <template #top-left
        ><a
          class="underline cursor-pointer text-b-500"
          @click="$emit('forgotPassword')"
          >{{ $t('login.forgot-password') }}</a
        ></template
      >
      <l-password-input
        v-model="password"
        :wrong-value="error"
        :placeholder="$t('inputs.password-text-placeholder')"
      />
    </l-field>
    <section class="flex h-6">
      <p v-show="error" class="text-r-400">{{ $t('login.error') }}</p>
    </section>
    <div class="flex justify-center flex-col">
      <l-button
        class="w-full"
        size="small"
        :class="{ 'is-loading': loggingIn }"
        :disabled="!isComplete"
        @click="startLogin"
      >
        {{ $t('ctas.login') }}
      </l-button>
      <l-button
        v-if="showPINOption"
        class="w-full pt-4"
        size="small"
        type="text"
        @click="$emit('activatePIN')"
      >
        {{ $t('login.login-pin') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  LButton,
  LField,
  LInput,
  LPasswordInput
} from '@last/core-ui/paprika/index.js'
import { computed, ref, watch } from 'vue'
import { useAuthStore } from '@/store/auth.js'

type Props = {
  showPINOption: boolean
}

withDefaults(defineProps<Props>(), {
  showPINOption: true
})

const authStore = useAuthStore()

const email = ref<string>()
const password = ref<string>()
const loggingIn = ref<boolean>(false)
const error = ref<boolean>(false)

defineEmits(['activatePIN', 'forgotPassword'])

const isComplete = computed(() => {
  return email.value && password.value
})

watch([email, password], () => {
  error.value = false
})

async function startLogin() {
  if (!isComplete.value) return
  loggingIn.value = true
  try {
    await authStore.login({ email: email.value!, password: password.value! })
  } catch (e) {
    error.value = true
  }
  loggingIn.value = false
}
</script>
