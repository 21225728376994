import { Tab } from '@last/core-ui/paprika/components/LTabs.vue'
import { ref, computed } from 'vue'
import { Modifier, ModifierGroupType, Product } from '@/types'
import { v4 as uuid } from 'uuid'
import { useI18n } from 'vue-i18n'
import { useCatalogStore } from '@/store/catalog'
import { storeToRefs } from 'pinia'
import { useTabsStore } from '@/store/tabs'

const TAB_COMMENT = 'TAB_COMMENT'
const TAB_OPEN_ATTRIBUTE = 'TAB_OPEN_ATTRIBUTE'

export function useProductEditor(product: Product) {
  const tabsStore = useTabsStore()
  const catalogStore = useCatalogStore()
  const { t } = useI18n()

  const { catalogs } = storeToRefs(catalogStore)
  const { updateProductQuantity } = tabsStore

  const quantity = ref<number>(1)
  const course = ref<string>('')
  const comments = ref<string>('')
  const selectedModifiers = ref<Record<string, number>[]>([])
  const openModifiers = ref<Modifier[]>([])

  const selectedTabId = ref<string>()

  const indexOfSelectedModifier = computed(() => {
    return tabs.value.map(tab => tab.id).indexOf(modifierGroup.value.id)
  })

  const tabs = computed<Tab[]>(() => {
    return [
      ...(product.modifierGroups || []).map(modifierGroup => ({
        name: modifierGroup.name,
        id: modifierGroup.id
      })),
      {
        name: t('ordering.open-modifiers'),
        id: TAB_OPEN_ATTRIBUTE
      },
      {
        name: t('ordering.comments'),
        id: TAB_COMMENT
      }
    ]
  })

  const modifierGroup = computed(() => {
    return (product.modifierGroups.find(
      modifierGroup => modifierGroup.id === selectedTabId.value
    ) || {}) as ModifierGroupType
  })

  const allCourses = computed(() => {
    return (
      Object.values(catalogs.value)[0]?.courses?.map((course: string) => ({
        label: course,
        value: course
      })) || []
    )
  })

  const allSelectedModifiers = computed(() => {
    const selectedModifiersFlat = selectedModifiers.value
      .flatMap(groupModifiers =>
        Object.keys(groupModifiers).map(modifierId => ({
          parentModifierId: modifierId,
          quantity: groupModifiers[modifierId]
        }))
      )
      .reduce(
        (res, modifier) => {
          res[modifier.parentModifierId] = modifier
          return res
        },
        {} as Record<string, { parentModifierId: string; quantity: number }>
      )
    const sortedModifiers = (product.modifierGroups || [])
      .flatMap(group => group.modifiers)
      .filter(modifier => selectedModifiersFlat[modifier.id])
      .map(modifier => ({
        id: uuid(),
        name: modifier.name,
        priceImpact: modifier.priceImpact,
        parentModifierId: modifier.id,
        quantity: selectedModifiersFlat[modifier.id].quantity
      }))
    return [...sortedModifiers, ...openModifiers.value]
  })

  const isMinimumComplete = computed(() => {
    if (!product.modifierGroups) return true
    return product.modifierGroups.every((group, groupIndex) => {
      if (!group.min || selectedModifiers.value.length === 0) return true
      return (
        group.min! <=
        Object.values(selectedModifiers.value[groupIndex]).reduce(
          (sum, value) => sum + value,
          0
        )
      )
    })
  })

  function deleteOpenModifier(id: string) {
    openModifiers.value = openModifiers.value.filter(
      modifier => modifier.id != id
    )
  }

  function updateQuantity(newQuantity: number) {
    quantity.value = newQuantity
    if (product.id) {
      updateProductQuantity({
        productId: product.id,
        quantity: newQuantity
      })
    }
  }

  function updateSelectedModifiers() {
    const groupQuantity = product.modifierGroups
      ? product.modifierGroups.length
      : 0
    selectedModifiers.value = Array.from({ length: groupQuantity }, () => ({}))
    if (product.modifiers) {
      const modifierIndexes: Record<string, number> = (
        product.modifierGroups || []
      )
        .flatMap((group, index) =>
          group.modifiers.map((modifier: any) => ({ id: modifier.id, index }))
        )
        .reduce((res: any, modifier) => {
          res[modifier.id] = modifier.index
          return res as any
        }, {})
      product.modifiers
        .filter(modifier => modifier.parentModifierId)
        .forEach(modifier => {
          const index = modifierIndexes[modifier.parentModifierId!]
          selectedModifiers.value[index][modifier.parentModifierId!] =
            modifier.quantity!
        })
      const modifierIds = new Set(Object.keys(modifierIndexes))
      openModifiers.value = product.modifiers.filter(
        modifier =>
          !modifier.parentModifierId ||
          !modifierIds.has(modifier.parentModifierId)
      )
    } else {
      openModifiers.value = []
    }
    comments.value = product.comments
    quantity.value = product.quantity
    course.value = product.course
  }

  return {
    quantity,
    course,
    comments,
    selectedModifiers,
    openModifiers,
    selectedTabId,
    indexOfSelectedModifier,
    tabs,
    modifierGroup,
    allCourses,
    allSelectedModifiers,
    isMinimumComplete,
    deleteOpenModifier,
    updateQuantity,
    updateSelectedModifiers
  }
}
