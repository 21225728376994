import generateBill from '@/tickets/bill.js'
import generateCourierReport from '@/tickets/courierReport.js'
import generateZReport from '@/tickets/zreport.js'
import generateXReport from '@/tickets/xreport.js'
import generateRealTimeReport from '@/tickets/realTimeReport.js'
import generateTillReport from '@/tickets/tillReport.js'
import generateKitchenOrder from '@/tickets/kitchenOrder.js'
import generateKitchenNote from '@/tickets/kitchenNote.js'
import generateCancelTab from '@/tickets/cancelTab.js'
import generatePayInPayOut from '@/tickets/payInPayOut.js'
import generateShiftMovements from '@/tickets/shiftMovements.js'
import generateBankReceipt from '@/tickets/bankReceipt.js'
import BillOrderFormatter from '@/tickets/billOrderFormatter.js'

async function render(parts, scale = 1, printer) {
  let canvas = document.createElement('canvas')
  var ctx = canvas.getContext('2d')
  let height = 0
  let width = printer?.ticketWidth || 320
  for (let part of parts) {
    height += await part.height(ctx, width)
  }
  height = Math.max(printer?.ticketHeight || 0, height)
  canvas.width = width * scale
  canvas.height = height * scale
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.scale(scale, scale)
  let top = 0
  for (let part of parts) {
    await part.render(top, ctx, width)
    top += await part.height(ctx, width)
  }
  return canvas
}

async function bill(bill, options, scale) {
  return await render(
    await generateBill(BillOrderFormatter.format(bill), options),
    scale
  )
}

async function courierReport(report, scale) {
  return await render(generateCourierReport(report), scale)
}

async function zReport(report, config, scale) {
  return await render(generateZReport(report, config), scale)
}

async function xReport(report, config, scale, isPreview) {
  return await render(generateXReport(report, config, isPreview), scale)
}

async function realTimeReport(report, config, scale) {
  return await render(generateRealTimeReport(report, config), scale)
}

async function tillReport(report, tillName, scale) {
  return await render(generateTillReport(report, tillName), scale)
}

async function kitchenOrder(order, printer, scale) {
  return await render(generateKitchenOrder(order, printer), scale, printer)
}

async function kitchenNote(courseLabel, tab, scale) {
  return await render(generateKitchenNote(courseLabel, tab), scale)
}

async function cancelTab(tab, scale) {
  return await render(generateCancelTab(tab), scale)
}

async function payInPayOut(movement, scale) {
  return await render(generatePayInPayOut(movement), scale)
}

async function shiftMovements(report, name, config, scale) {
  return await render(generateShiftMovements(report, name, config), scale)
}

async function bankReceipt(payment, scale) {
  return await render(generateBankReceipt(payment), scale)
}

export default {
  bill,
  courierReport,
  zReport,
  xReport,
  realTimeReport,
  tillReport,
  kitchenOrder,
  kitchenNote,
  cancelTab,
  payInPayOut,
  shiftMovements,
  bankReceipt
}
