<template>
  <table>
    <thead class="bg-n-600 h-12">
      <tr>
        <th class="px-2 align-middle text-left font-normal rounded-l-xl">
          {{ $t('couriers.identifier') }}
        </th>
        <th class="w-1/3 px-2 align-middle text-left font-normal">
          {{ $t('couriers.address') }}
        </th>
        <th class="px-2 align-middle text-left font-normal">
          {{ $t('couriers.pickup-delivery') }}
        </th>

        <th class="px-2 align-middle text-left font-normal">
          {{ $t('couriers.payment-method') }}
        </th>
        <th class="px-2 align-middle text-left font-normal rounded-r-xl">
          {{ $t('couriers.total') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in report" :key="row.id">
        <td class="p-2">
          {{ row.code }}
        </td>
        <td class="p-2">
          {{ row.address }}
        </td>
        <td class="p-2">
          <div class="flex flex-col">
            <div>
              {{ $d(row.creationTime, 'date') }}
            </div>
            <div>
              {{ $$d(row.closeTime, 'date') }}
            </div>
          </div>
        </td>
        <td class="p-2">
          {{ getPaymentMethodLabel(row.paymentMethod) }}
        </td>
        <td class="p-2">
          {{ $filters.currency(row.total) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps } from 'vue'

defineProps({
  report: {
    type: Array,
    default: () => []
  }
})

const { t } = useI18n()

function getPaymentMethodLabel(method: string) {
  switch (method) {
    case 'cash':
      return t('couriers.cash')
    case 'card':
      return t('couriers.card')
    default:
      return method
  }
}
</script>
