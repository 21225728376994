<template>
  <div class="w-full overflow-y-scroll scrolling-touch">
    <div>
      <div
        class="py-3 px-4 font-body text-n-0 border-b border-n-700"
        @click="selectProducts(tabSharedProducts)"
      >
        {{ $t('ordering.shared') }}
      </div>
      <product-component
        v-for="product in tabSharedProducts"
        :key="product.uniqueId"
        :product="product"
        :disabled="product.disabled"
        :is-selected="selectedUniqueIds.includes(product.uniqueId)"
        allow-remove
        allow-discount
        :show-full-quantity="false"
        show-billing-status
        @selected="toggleProduct(product)"
      />
    </div>
    <div v-for="(seat, index) in tabSeats" :key="index">
      <div
        class="py-3 px-4 font-body text-n-0 border-b border-n-700"
        @click="selectProducts(seat)"
      >
        {{ $t('ordering.seat') }} {{ index + 1 }}
      </div>
      <product-component
        v-for="product in seat"
        :key="product.uniqueId"
        :product="{ ...product, seat: index }"
        :is-selected="selectedUniqueIds.includes(product.uniqueId)"
        allow-remove
        allow-discount
        :show-full-quantity="false"
        show-billing-status
        @selected="toggleProduct(product)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ProductComponent from '@/components/core/Product.vue'
import { useTabs } from '@/composables/useTabs'
import { TabProduct } from '@/types'
import { computed } from 'vue'

const props = defineProps<{
  tabId: string
  selectedProductIds: { id: string; uniqueId: string }[]
}>()

const emit = defineEmits(['toggleProduct'])

const { seatProducts, sharedProducts } = useTabs(props.tabId)

const tabSeats = computed(() => {
  return seatProducts.value.map(products => splitProducts(products))
})

const tabSharedProducts = computed(() => {
  return splitProducts(sharedProducts.value)
})

const selectedUniqueIds = computed(() => {
  return props.selectedProductIds.map(id => id.uniqueId)
})

function toggleProduct(product: TabProduct & { uniqueId: string }) {
  emit('toggleProduct', {
    id: product.id,
    uniqueId: product.uniqueId
  })
}

function splitProducts(
  products: (TabProduct & {
    notBilledQuantity: number
    notPaidQuantity: number
  })[]
) {
  return products.flatMap(product => {
    return Array(product.quantity)
      .fill('')
      .map((_, index) => {
        let discount2x1 =
          product.discountType === '2x1' &&
          index + 1 > product.quantity - product.discountAmount!
        return {
          ...product,
          uniqueId: product.id + index,
          discount2x1,
          paid:
            product.quantity - product.notPaidQuantity > index || discount2x1,
          disabled:
            product.quantity - product.notBilledQuantity > index || discount2x1
        }
      })
  })
}

function selectProducts(
  products: (TabProduct & {
    disabled: boolean
    uniqueId: string
  })[]
) {
  let enabledProducts = products.filter(product => !product.disabled)
  let unselected = enabledProducts.filter(
    product => !selectedUniqueIds.value.includes(product.uniqueId)
  )
  if (unselected.length > 0) {
    unselected.forEach(toggleProduct)
  } else {
    enabledProducts.forEach(toggleProduct)
  }
}
</script>
